import React, { useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { Button, Modal } from '@redislabsdev/redis-ui-components';
import { Loader } from '@redislabsdev/redislabs-ui-components';
import { showToast } from '../../components/Toast/Toast';
import NotificationsForm, { NotificationsFormState } from './NotificationsForm';
import { ErrorComponent } from './MaintenanceWindowPage.style';
import { NotificationRequest, useCreateNotification } from './hooks/useCreateNotification';
import { EmailTemplatesResponse, getEmailTemplatesRequest } from './MaintenanceWindowPage.api';

const NotificationsFormWrapper = () => {
  const [error, setError] = useState<string | undefined>();
  const [formValues, setFormValues] = useState<NotificationRequest | undefined>();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(true);
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplatesResponse[]>([]);

  const queryParams = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    return {
      rcp_id: urlParams.get('rcp_id'),
      mesh_id: urlParams.get('mesh_id'),
    };
  }, [location]);

  const initialFormState: NotificationsFormState = useMemo(() => {
    const rcpId = queryParams.rcp_id ? (queryParams.rcp_id as string) : '';
    const meshId = queryParams.mesh_id ? (queryParams.mesh_id as string) : '';

    return {
      rcpId,
      meshId,
      emailTemplate: '',
    };
  }, []);

  const createNotificationMutation = useCreateNotification({
    onSuccess: () => {
      showToast('Notification successfully submitted.', 'success');
    },
    onError: (details) => {
      const errorMessage = details.response?.data.error.message || 'Error submitting notification';
      setError(errorMessage);
    },
  });

  useEffect(() => {
    getEmailTemplatesRequest()
      .then((result) => {
        setEmailTemplates(result.data);
      })
      .catch(() => {
        setError('Unable to fetch email templates.');
      })
      .finally(() => {
        setIsLoadingTemplates(false);
      });
  }, []);

  const selectableTemplates = useMemo(
    () =>
      emailTemplates
        .filter((template) => template.maintenance_type === 'general')
        .map((template) => ({
          id: template.friendly_name,
          title: template.friendly_name,
        })),
    [emailTemplates]
  );

  return (
    <>
      <Formik
        initialValues={initialFormState}
        onSubmit={(values, { setErrors }) => {
          setError(undefined);

          if (!values.emailTemplate) {
            setErrors({
              emailTemplate: 'Email template is required.',
            });
            return;
          }

          const formattedValues: NotificationRequest = {
            ...(values.rcpId ? { rcpId: parseInt(values.rcpId, 10) } : {}),
            ...(values.meshId ? { meshId: parseInt(values.meshId, 10) } : {}),
            emailTemplate: values.emailTemplate,
          };
          setFormValues(formattedValues);
          setIsConfirmModalOpen(true);
        }}
      >
        <>
          {isLoadingTemplates && (
            <div data-testid="mw-fetching-indicator">
              <Loader />
            </div>
          )}
          {!error && !isLoadingTemplates && !selectableTemplates.length && (
            <ErrorComponent>
              There are no available generic notification email templates. Please first add at least
              one generic notification email template first.
            </ErrorComponent>
          )}
          {error && <ErrorComponent>{error}</ErrorComponent>}
          {!isLoadingTemplates && !!selectableTemplates.length && (
            <NotificationsForm
              isSubmitting={createNotificationMutation.isLoading}
              selectableTemplates={selectableTemplates}
            />
          )}
        </>
      </Formik>
      <Modal.Compose open={isConfirmModalOpen}>
        <Modal.Content.Compose>
          <Modal.Content.Header title="Confirm"></Modal.Content.Header>
          <Modal.Content.Body.Compose>
            <p>
              Are you sure you would like to send a &quot;
              {
                selectableTemplates.find((template) => template.id === formValues?.emailTemplate)
                  ?.title
              }
              &quot; email to{' '}
              {formValues?.meshId ? `mesh #${formValues.meshId}` : `cluster #${formValues?.rcpId}`}?
            </p>
          </Modal.Content.Body.Compose>
          <Modal.Content.Footer.Compose style={{ justifyContent: 'right' }}>
            <Button
              type="button"
              variant="secondary-ghost"
              size="large"
              onClick={() => {
                setIsConfirmModalOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              size="large"
              style={{ marginLeft: '.5rem' }}
              onClick={() => {
                setIsConfirmModalOpen(false);
                if (formValues) {
                  createNotificationMutation.mutate(formValues);
                }
              }}
            >
              Submit
            </Button>
          </Modal.Content.Footer.Compose>
        </Modal.Content.Compose>
      </Modal.Compose>
    </>
  );
};

export default NotificationsFormWrapper;
