import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { api, buildUrl } from '../../../api/config';
import { MaintenanceWindowErrorResponse } from './types';

export type NotificationRequest = {
  rcpId?: number | null;
  meshId?: number | null;
  emailTemplate: string;
};

const createNotificationRequest = (request: NotificationRequest) =>
  api.post(`${buildUrl('maintenanceWindow')}/notification`, request);

export const useCreateNotification = (
  options?: UseMutationOptions<
    AxiosResponse,
    AxiosError<MaintenanceWindowErrorResponse>,
    NotificationRequest
  >
) => {
  return useMutation<
    AxiosResponse,
    AxiosError<MaintenanceWindowErrorResponse>,
    NotificationRequest
  >(
    'create-notification',
    (request: NotificationRequest) => {
      return createNotificationRequest(request);
    },
    options
  );
};
