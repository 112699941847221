import { useSelector } from 'react-redux';
import { StoreInterface } from '../../interfaces/storeInterfaces';

export interface ProtectedComponentProps {
  requiredPermissions: string | string[];
  children: React.ReactElement;
  partialPermissions?: boolean;
}

export const userHasPermission = (
  permissionKey: string | string[],
  userPermissions: string[],
  partialPermissions?: boolean
) => {
  if (Array.isArray(permissionKey)) {
    if (partialPermissions) {
      return permissionKey.some((permission) => userPermissions.includes(permission));
    }

    return permissionKey.every((permission) => userPermissions.includes(permission));
  }

  return userPermissions.includes(permissionKey);
};

export const useUserHasPermission = (
  permissionKey: string | string[],
  partialPermissions?: boolean
) => {
  const userPermissions = useSelector((state: StoreInterface) => state.rootPage.permissions);
  return userHasPermission(permissionKey, userPermissions, partialPermissions);
};

const ProtectedComponent = ({
  requiredPermissions,
  partialPermissions,
  children,
}: ProtectedComponentProps) => {
  const permissions = useSelector((state: StoreInterface) => state.rootPage.permissions);

  const hasPermissions = userHasPermission(requiredPermissions, permissions, partialPermissions);

  if (!hasPermissions) {
    return null;
  }

  return children;
};

export default ProtectedComponent;
