import { IconButton } from '@redislabsdev/redis-ui-components';
import { DeleteIcon, EditIcon } from '@redislabsdev/redis-ui-icons';
import { Loader, Tooltip } from '@redislabsdev/redislabs-ui-components';
import { TablePagination } from '@redislabsdev/redislabs-ui-components/ui/components/Table';
import { addDays, parseISO } from 'date-fns';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import ProtectedComponent from '../../components/ProtectedComponent/ProtectedComponent';
import { MAINTENANCE_WINDOW_WRITE } from '../../constants/permissionsConstants';
import { MaintenanceWindowExclusionsFilters, Sort } from './MaintenanceWindow.types';
import { Exclusion } from './MaintenanceWindowPage.api';
import { RightAlignCell, StyledPre, RedCell, OrangeCell } from './Table.style';
import formatInTimeZone from './formatInTimeZone';
import Table, { TableProps } from './Table';

const renderDate = (date: string) =>
  date ? <div>{formatInTimeZone(parseISO(date), 'd-MMM-y HH:mm:ss', 'UTC')}</div> : null;

const tableColumns = ({
  onEditClick,
  onDeleteClick,
  setSelectedExclusionIds,
  selectedExclusionIds,
  exclusions,
}): TableProps<Exclusion>['columns'] => [
  {
    protectedBy: MAINTENANCE_WINDOW_WRITE,
    headerKey: 'checkbox',
    header: () => {
      const opsExclusions = exclusions.filter((ex) => ex.initiator === 'Ops');
      return (
        <input
          type="checkbox"
          checked={!!opsExclusions.length && selectedExclusionIds.length === opsExclusions.length}
          disabled={!opsExclusions.length}
          onChange={(e) => {
            setSelectedExclusionIds(
              e.target.checked ? opsExclusions.map((ex) => ex.exclusionId) : []
            );
          }}
        />
      );
    },
    render: ({ exclusionId, initiator }) => (
      <input
        type="checkbox"
        checked={selectedExclusionIds.includes(exclusionId)}
        disabled={initiator !== 'Ops'}
        onChange={(e) => {
          if (e.target.checked) {
            setSelectedExclusionIds((prev: number[]) =>
              exclusions
                .map((ex) => ex.exclusionId)
                .filter((id) => prev.includes(id) || id === exclusionId)
            );
          } else {
            setSelectedExclusionIds((prev: number[]) => prev.filter((id) => id !== exclusionId));
          }
        }}
      />
    ),
  },
  {
    header: 'RCP ID',
    render: 'rcpId',
    sortKey: 'rcpId',
  },
  {
    header: 'Mesh ID',
    render: 'meshId',
    sortKey: 'meshId',
  },
  {
    header: 'Subscription ID',
    render: 'subscriptionId',
    sortKey: 'subscriptionId',
  },
  {
    header: 'Account ID',
    render: 'accountId',
    sortKey: 'accountId',
  },
  {
    header: 'Account Name',
    render: 'accountName',
    sortKey: 'accountName',
  },
  {
    header: 'Exclusion Start Time',
    render: ({ start }: Exclusion) => renderDate(start),
    sortKey: 'start',
  },
  {
    header: 'Exclusion End Time',
    render: ({ end, initiator }: Exclusion) => {
      const currentDate = new Date();
      const endDate = new Date(end);
      if (initiator === 'Ops') {
        // ops-initiated `end` dates are adjusted to the PENDING expiration
        // date; a hard expiration would have occurred 7 days past the `end`
        // value received
        const endDatePlusSevenDays = addDays(endDate, 7);
        if (endDatePlusSevenDays < currentDate) {
          return <RedCell>{renderDate(end)}</RedCell>;
        }
        if (endDate < currentDate) {
          // pending expiration
          return <OrangeCell>{renderDate(end)}</OrangeCell>;
        }
      }
      // customer-initiated `end` dates are true end dates, so if they are
      // before the current date, they are truly expired
      if (endDate < currentDate) {
        return <RedCell>{renderDate(end)}</RedCell>;
      }
      // base case
      return renderDate(end);
    },
    sortKey: 'end',
  },
  {
    header: 'Operations',
    render: ({ operations }) => <>{operations.join(', ')}</>,
  },
  {
    header: 'Exclusion Reason',
    render: ({ reason }: Exclusion) => {
      if (!reason) {
        return '';
      }
      if (reason.length > 50) {
        return (
          <Tooltip tooltipContent={reason} textColor="#01112a" delayHide={100}>
            <StyledPre>{reason.substring(0, 50)}...</StyledPre>
          </Tooltip>
        );
      }
      return <StyledPre>{reason}</StyledPre>;
    },
  },
  {
    header: 'Initiator',
    render: 'initiator',
  },
  {
    header: '',
    cellComponent: RightAlignCell,
    render: (row) => {
      if (row.initiator === 'Ops') {
        const currentDate = new Date();
        const pendingExpirationDate = new Date(row.end);
        const hardExpirationDate = addDays(pendingExpirationDate, 7);
        return (
          <ProtectedComponent requiredPermissions={MAINTENANCE_WINDOW_WRITE}>
            <>
              <IconButton icon={EditIcon} onClick={() => onEditClick(row)} />
              {hardExpirationDate >= currentDate && (
                <IconButton
                  style={{ marginLeft: '1rem' }}
                  icon={DeleteIcon}
                  onClick={() => onDeleteClick(row)}
                />
              )}
            </>
          </ProtectedComponent>
        );
      }
      return null;
    },
  },
];

export type ExclusionTableProps = {
  exclusions: Exclusion[];
  fetchExclusions: () => void;
  totalRecords: number;
  size: number;
  setSize: Dispatch<SetStateAction<number>>;
  loading: boolean;
  filters: MaintenanceWindowExclusionsFilters;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  sort: Sort | null;
  setSort: React.Dispatch<React.SetStateAction<Sort | null>>;
  onEditClick: (row: Exclusion) => void;
  onDeleteClick: (row: Exclusion) => void;
  setSelectedExclusionIds: Dispatch<SetStateAction<number[]>>;
  selectedExclusionIds: number[];
};

const ExclusionTable: React.FC<ExclusionTableProps> = ({
  exclusions,
  fetchExclusions,
  loading = false,
  totalRecords = 0,
  size = 0,
  setSize,
  filters,
  page,
  setPage,
  sort,
  setSort,
  onEditClick,
  onDeleteClick,
  setSelectedExclusionIds,
  selectedExclusionIds,
}) => {
  const columns = tableColumns({
    onEditClick,
    onDeleteClick,
    setSelectedExclusionIds,
    selectedExclusionIds,
    exclusions,
  });

  useEffect(() => {
    fetchExclusions();
  }, [page, size, filters, sort]);

  const pageCount = Math.ceil(totalRecords / size);
  return (
    <div data-testid="exclusions">
      {loading && (
        <div data-testid="exclusions-fetching-indicator">
          <Loader />
        </div>
      )}
      <div hidden={loading}>
        <Table
          columns={columns}
          data={exclusions}
          sortKey={sort?.sortBy}
          sortDirection={sort?.sortDirection}
          onSort={({ sortKey, sortDirection }) => {
            setPage(0);
            setSort({ sortBy: sortKey, sortDirection });
          }}
          rowKey="exclusionId"
        />
        <TablePagination
          isPaginatedControlled={true}
          rows={exclusions}
          canNextPage={page + 1 < pageCount}
          canPreviousPage={page > 0}
          pageIndex={page}
          pageCount={pageCount}
          pageSize={size}
          gotoPage={(pageIndex: number) => {
            setPage(pageIndex);
          }}
          nextPage={() => {
            setPage(page + 1);
          }}
          previousPage={() => {
            setPage(page - 1);
          }}
          setPageSize={(newSize: number) => {
            setSize(newSize);
          }}
        />
      </div>
    </div>
  );
};

export default ExclusionTable;
