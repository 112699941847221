import styled from 'styled-components/macro';

export const ErrorComponent = styled.div`
  padding: 0.8rem 1.2rem;
  border-radius: 0.8rem;
  width: fit-content;
  border-width: 0.1rem;
  border-style: solid;
  background-color: #feedee;
  color: #631e23;
  border-color: #f98189;
  margin-top: 1.2rem;
  width: 100%;

  ul {
    margin-top: 1.2rem;
  }

  ul li {
    margin-left: 1.2rem;
    display: list-item;
    list-style-position: inside;
    list-style: circle;
  }

  a {
    color: #631e23;
    text-decoration: underline;
  }
`;

export const SuccessComponent = styled(ErrorComponent)`
  background-color: rgb(239, 249, 245);
  color: rgb(36, 79, 62);
  border-color: rgb(140, 215, 185);

  a {
    color: rgb(36, 79, 62);
    text-decoration: underline;
  }
`;

export const WarningComponent = styled(ErrorComponent)`
  background-color: rgb(255, 247, 234);
  color: rgb(102, 70, 17);
  border-color: rgb(255, 199, 107);

  a {
    color: rgb(36, 79, 62);
    text-decoration: underline;
  }
`;

export const PaddedTabContainer = styled.div`
  margin-top: 1rem;
`;
