import styled from 'styled-components/macro';

export const Cell = styled.div<{ editMode?: boolean }>`
  display: ${({ editMode }) => (editMode ? 'flex' : 'block')};
`;

export const TextWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const InputWrapper = styled.div`
  width: 100%;
`;

export const StatusContainer = styled.div`
  margin-left: 1.1rem;
`;

export const TooltipText = styled.p`
  padding: 9px 14px;
  text-align: center;
  align-self: center;
`;
