import { useQuery } from 'react-query';
import omit from 'lodash/omit';
import { api, buildUrl } from '../../../api/config';
import { blankFilters, buildArrayFilters, ClustersResponse } from '../MaintenanceWindowPage.api';
import { MaintenanceWindowClustersFilters, Sort } from '../MaintenanceWindow.types';

const clustersArrayFilterFields = ['providers', 'regions', 'maintenanceActivity'];

const getMaintenanceWindowTableDataApiRequest = (
  page: number,
  size: number,
  filters: MaintenanceWindowClustersFilters,
  sort: Sort | null
) =>
  api.get<ClustersResponse>(
    `${buildUrl('maintenanceWindow')}/clusters${buildArrayFilters(
      filters,
      clustersArrayFilterFields
    )}`,
    {
      params: {
        page,
        size,
        ...omit(filters, clustersArrayFilterFields, blankFilters(filters)),
        ...sort,
      },
    }
  );

export const useClusters = (
  page: number,
  size: number,
  filters: MaintenanceWindowClustersFilters,
  sort: Sort | null
) => {
  return useQuery({
    queryKey: ['clusters', page, size, filters, sort],
    queryFn: async () => {
      const { data } = await getMaintenanceWindowTableDataApiRequest(page, size, filters, sort);
      return data;
    },
    refetchOnWindowFocus: false,
  });
};
