import { Button, Modal } from '@redislabsdev/redis-ui-components';
import { Loader, showToast } from '@redislabsdev/redislabs-ui-components';
import { parseISO, subDays } from 'date-fns';
import { Formik } from 'formik';
import moment from 'moment';
import { useState } from 'react';
import styled from 'styled-components';
import { array, date, number, object, ref, string } from 'yup';
import ProtectedComponent from '../../components/ProtectedComponent/ProtectedComponent';
import {
  MAINTENANCE_WINDOW_WRITE,
  MAINTENANCE_WINDOW_READ,
} from '../../constants/permissionsConstants';
import { api, buildUrl } from '../../api/config';
import ExclusionForm from './ExclusionForm';
import ExclusionTable from './ExclusionTable';
import ExclusionTableFilters from './ExclusionTableFilters';
import {
  ExclusionFormState,
  MaintenanceWindowExclusionsFilters,
  Sort,
} from './MaintenanceWindow.types';
import {
  AddExclusionRequest,
  EditExclusionRequest,
  Exclusion,
  addExclusionRequest,
  expireExclusionsRequest,
  editExclusionRequest,
  getExclusionTableDataApiRequest,
} from './MaintenanceWindowPage.api';
import { TableControls } from './Table.style';
import formatInTimeZone from './formatInTimeZone';
import { PaddedTabContainer } from './MaintenanceWindowPage.style';
import { ExpandableContent } from './ExpandableContent';
import BulkExclusionForm from './BulkExclusionForm';

const ErrorComponent = styled.div`
  padding: 0.8rem 1.2rem;
  border-radius: 0.8rem;
  width: fit-content;
  border-width: 0.1rem;
  border-style: solid;
  background-color: #feedee;
  color: #631e23;
  border-color: #f98189;
  margin-top: 1.2rem;
  width: 100%;
`;

const FormWrapper = styled.div`
  width: 60rem;
`;

const formatDate = (date: string) => formatInTimeZone(parseISO(date), 'd-MMM-y HH:mm:ss', 'UTC');

type ExclusionsProps = {
  filters: MaintenanceWindowExclusionsFilters;
  handleFiltersSubmit: (values: MaintenanceWindowExclusionsFilters) => void;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  sort: Sort | null;
  setSort: React.Dispatch<React.SetStateAction<Sort | null>>;
};

const addExclusionSchema = (exclusionFormType: string) => {
  const ids =
    exclusionFormType === 'cluster'
      ? {
          rcpId: number()
            .min(1)
            .when('meshId', {
              is: (meshId) => !meshId,
              then: number().required('rcpId or meshId is required'),
            })
            .when('meshId', {
              is: (meshId) => meshId > 0,
              then: number().max(0, 'rcpId and meshId cannot both be defined'),
            }),
          meshId: number()
            .min(1)
            .when('rcpId', {
              is: (rcpId) => !rcpId,
              then: number().required('rcpId or meshId is required'),
            })
            .when('rcpId', {
              is: (rcpId) => rcpId > 0,
              then: number().max(0, 'rcpId and meshId cannot both be defined'),
            }),
        }
      : {
          accountId: number().min(1).required(),
        };

  return object().shape(
    {
      ...ids,
      operations: array().required().min(1),
      reason: string().max(3000),
      startDate: date().required(),
      endDate: date().required().min(ref('startDate'), "end date can't be before start date"),
    },
    ['rcpId', 'meshId', 'accountId']
  );
};

const editExclusionSchema = object().shape({
  operations: array().required().min(1),
  reason: string().max(3000),
  startDate: date().required(),
  endDate: date().required().min(ref('startDate'), "end date can't be before start date"),
});

const initialStartDate = moment.utc(new Date()).toDate();
initialStartDate.setUTCHours(0, 0, 0, 0);

const initialEndDate = moment.utc(new Date()).toDate();
initialEndDate.setUTCHours(23, 59, 59, 999);

const initialExclusionFormValues: ExclusionFormState = {
  rcpId: '',
  meshId: '',
  accountId: '',
  operations: [],
  reason: '',
  startDate: initialStartDate,
  endDate: initialEndDate,
};

const initialPageSize = 10;

const Exclusions: React.FC<ExclusionsProps> = ({
  filters,
  handleFiltersSubmit,
  page,
  setPage,
  sort,
  setSort,
}) => {
  const [exclusionFormType, setExclusionFormType] = useState<'cluster' | 'account' | undefined>();
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isBulkEditFormOpen, setIsBulkEditFormOpen] = useState(false);
  const [editValues, setEditValues] = useState<Exclusion>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleteMultipleModalOpen, setIsDeleteMultipleModalOpen] = useState(false);
  const [deleteValues, setDeleteValues] = useState<Exclusion>();
  const [exclusions, setExclusions] = useState<Exclusion[]>([]);
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [isExporting, setIsExporting] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [size, setSize] = useState(initialPageSize);
  const [selectedExclusionIds, setSelectedExclusionIds] = useState<number[]>([]);

  const currentDateMinusSevenDays = subDays(new Date(), 7);
  const selectedExclusionIdsEligibleForExpiration = selectedExclusionIds.filter((id) => {
    const exclusion = exclusions.find((e) => e.exclusionId === id);
    if (exclusion) {
      const pendingExpirationDate = new Date(exclusion.end);
      return pendingExpirationDate > currentDateMinusSevenDays;
    }
    return false;
  });

  // The MultiSelect component triggers a form submit when hitting the delete all button
  // This flag prevents that component from submitting the form
  const [shouldSubmitForm, setShouldSubmitForm] = useState(false);

  const onAddClick = (exclusionType: 'account' | 'cluster') => {
    setExclusionFormType(exclusionType);
    setIsAddFormOpen(true);
  };

  const onEditClick = (row: Exclusion) => {
    setEditValues(row);
    setIsEditFormOpen(true);
  };

  const onDeleteClick = (row: Exclusion) => {
    setDeleteValues(row);
    setIsDeleteModalOpen(true);
  };

  const onSubmitClick = () => {
    setShouldSubmitForm(true);
  };

  const onDownloadClick = async () => {
    setIsExporting(true);
    const url = `${buildUrl('maintenanceWindow')}/exclusions/export`;

    api
      .get(url, {
        responseType: 'blob',
      })
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file and click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'exclusions.csv');
        document.body.appendChild(link);
        link.click();

        // cleanup
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch(() => {
        showToast('Error exporting data. Please try again at a later time.', 'error');
      })
      .finally(() => {
        setIsExporting(false);
      });
  };

  const fetchExclusions = (inputPage = page) => {
    setLoading(true);
    getExclusionTableDataApiRequest(inputPage, size, filters, sort)
      .then((result) => {
        setExclusions(result.data.records);
        setTotalRecords(result.data.totalRecords);

        // clear the selected exclusions any time the table is refreshed
        setSelectedExclusionIds([]);
      })
      .catch(() => {
        showToast('Error fetching data');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const editFormValues: ExclusionFormState = editValues
    ? {
        rcpId: editValues?.rcpId?.toString() || '',
        meshId: editValues?.meshId?.toString() || '',
        accountId: editValues?.accountId?.toString() || '',
        operations: editValues?.operations || [],
        reason: editValues?.reason || '',
        startDate: editValues?.start ? new Date(editValues.start) : new Date(),
        endDate: editValues?.end ? new Date(editValues.end) : new Date(),
      }
    : initialExclusionFormValues;

  const handleAddExclusionSubmit = async (values: ExclusionFormState) => {
    if (!shouldSubmitForm) {
      return;
    }

    try {
      const exclusion: AddExclusionRequest = {
        rcpId: values.rcpId ? parseInt(values.rcpId, 10) : undefined,
        meshId: values.meshId ? parseInt(values.meshId, 10) : undefined,
        accountId: values.accountId ? parseInt(values.accountId, 10) : undefined,
        operations: values.operations,
        reason: values.reason || undefined,
        start: values.startDate.toISOString(),
        end: values.endDate.toISOString(),
      };
      await addExclusionRequest(exclusion);
      setShouldSubmitForm(false);
      setIsAddFormOpen(false);
      setError(undefined);
    } catch (e) {
      const errorMessage = (e as { response: { data: { error: string } } }).response.data.error;
      setError(errorMessage);
    }
  };

  const handleEditExclusionSubmit = async (values: ExclusionFormState) => {
    if (!shouldSubmitForm || !editValues?.exclusionId) {
      return;
    }

    try {
      const exclusion: EditExclusionRequest = {
        exclusionId: editValues.exclusionId,
        operations: values.operations,
        reason: values.reason || undefined,
        start: values.startDate.toISOString(),
        end: values.endDate.toISOString(),
      };
      await editExclusionRequest(exclusion);
      setIsEditFormOpen(false);
      setShouldSubmitForm(false);
      setError(undefined);
    } catch (e) {
      const errorMessage = (e as { response: { data: { error: string } } }).response.data.error;
      setError(errorMessage);
    }
  };

  const handleDeleteExclusionSubmit = async () => {
    if (!deleteValues?.exclusionId) {
      return;
    }

    setLoading(true);

    try {
      await expireExclusionsRequest([deleteValues?.exclusionId]);

      // decrement page if we have deleted the last item on page
      const newPageNumber = page > 0 && exclusions.length === 1 ? page - 1 : page;
      setPage(newPageNumber);

      fetchExclusions(newPageNumber);
    } catch (error) {
      showToast('An error occurred while deleting exclusion.');
      setLoading(false);
    }
  };

  const handleDeleteExclusionsSubmit = async () => {
    setLoading(true);
    try {
      await expireExclusionsRequest(selectedExclusionIdsEligibleForExpiration);

      // decrement page if we have deleted all of the exclusions on the last page
      const onLastPage = Math.ceil(totalRecords / size) === page + 1;
      const newPageNumber =
        page > 0 &&
        onLastPage &&
        (filters.exclusionExpiredStatus === 'active' ||
          filters.exclusionExpiredStatus === 'pendingExpiration') &&
        exclusions.length === selectedExclusionIdsEligibleForExpiration.length
          ? page - 1
          : page;
      setPage(newPageNumber);

      fetchExclusions(newPageNumber);
    } catch (error) {
      showToast('An error occurred while deleting exclusions.');
      setLoading(false);
    }
  };

  if (isAddFormOpen) {
    return (
      <FormWrapper>
        {error && <ErrorComponent>{error}</ErrorComponent>}
        <Formik
          initialValues={initialExclusionFormValues}
          validationSchema={() => addExclusionSchema(exclusionFormType || '')}
          onSubmit={handleAddExclusionSubmit}
        >
          <ExclusionForm
            mode="add"
            exclusionType={exclusionFormType}
            onFormClose={() => {
              setIsAddFormOpen(false);
              setError(undefined);
            }}
            onSubmitClick={onSubmitClick}
          />
        </Formik>
      </FormWrapper>
    );
  }

  if (isEditFormOpen) {
    return (
      <FormWrapper>
        {error && <ErrorComponent>{error}</ErrorComponent>}
        <Formik
          initialValues={editFormValues}
          validationSchema={editExclusionSchema}
          onSubmit={handleEditExclusionSubmit}
        >
          <ExclusionForm
            mode="edit"
            onFormClose={() => {
              setIsEditFormOpen(false);
              setError(undefined);
            }}
            onSubmitClick={onSubmitClick}
          />
        </Formik>
      </FormWrapper>
    );
  }

  if (isBulkEditFormOpen) {
    return (
      <BulkExclusionForm
        exclusionIds={selectedExclusionIds}
        onClose={() => {
          setIsBulkEditFormOpen(false);
          setSelectedExclusionIds([]);
          setPage(0);
        }}
      />
    );
  }

  return (
    <PaddedTabContainer>
      <Modal.Compose open={isDeleteModalOpen} onOpenChange={setIsDeleteModalOpen}>
        <Modal.Content.Compose
          style={{
            width: '50rem',
          }}
        >
          <Modal.Content.Header title="Mark exclusion as expired?" />
          <Modal.Content.Body.Compose>
            <p style={{ padding: '1rem 0', fontWeight: 'bold' }}>
              This will set the exclusion end date to the current time.
            </p>
            <ul>
              {deleteValues?.rcpId && <li>RCP ID: {deleteValues.rcpId}</li>}
              {deleteValues?.meshId && <li>Mesh ID: {deleteValues.meshId}</li>}
              {deleteValues?.accountId && <li>Account ID: {deleteValues.accountId}</li>}
              {deleteValues?.subscriptionId && (
                <li>Subscription ID: {deleteValues.subscriptionId}</li>
              )}
              {deleteValues?.start && <li>Expiration Start: {formatDate(deleteValues.start)}</li>}
              {deleteValues?.end && <li>Expiration End: {formatDate(deleteValues.end)}</li>}
              {deleteValues?.operations && (
                <li>Operations: {deleteValues.operations.join(', ')}</li>
              )}
              {deleteValues?.reason && <li>Reason: {deleteValues.reason}</li>}
            </ul>
          </Modal.Content.Body.Compose>
          <Modal.Content.Footer
            onPrimaryButtonClick={handleDeleteExclusionSubmit}
            primaryButtonText="Confirm"
            secondaryButtonText="Cancel"
          />
        </Modal.Content.Compose>
      </Modal.Compose>
      <Modal.Compose open={isDeleteMultipleModalOpen} onOpenChange={setIsDeleteMultipleModalOpen}>
        <Modal.Content.Compose
          style={{
            width: '50rem',
          }}
        >
          <Modal.Content.Header
            title={`Mark ${selectedExclusionIdsEligibleForExpiration.length} exclusion${
              selectedExclusionIdsEligibleForExpiration.length > 1 ? 's' : ''
            } as expired?`}
          />
          <Modal.Content.Body.Compose>
            <p style={{ padding: '1rem 0', fontWeight: 'bold' }}>
              This will set the exclusion end dates to the current time.
            </p>
            {selectedExclusionIdsEligibleForExpiration.map((id) => {
              const exclusion = exclusions.find((exclusion) => exclusion.exclusionId === id);

              const headerText = exclusion?.rcpId
                ? `RCP ID: ${exclusion.rcpId}`
                : exclusion?.meshId
                ? `Mesh ID: ${exclusion.meshId}`
                : exclusion?.accountId
                ? `Account ID: ${exclusion.accountId}`
                : '';

              return (
                <ExpandableContent headerText={headerText} key={id}>
                  <ul style={{ marginBottom: '1rem' }}>
                    {(exclusion?.rcpId || exclusion?.meshId) && (
                      <li>Account ID: {exclusion.accountId}</li>
                    )}
                    {exclusion?.subscriptionId && (
                      <li>Subscription ID: {exclusion.subscriptionId}</li>
                    )}
                    {exclusion?.start && <li>Expiration Start: {formatDate(exclusion.start)}</li>}
                    {exclusion?.end && <li>Expiration End: {formatDate(exclusion.end)}</li>}
                    {exclusion?.operations && (
                      <li>Operations: {exclusion.operations.join(', ')}</li>
                    )}
                    {exclusion?.reason && <li>Reason: {exclusion.reason}</li>}
                  </ul>
                </ExpandableContent>
              );
            })}
          </Modal.Content.Body.Compose>
          <Modal.Content.Footer
            onPrimaryButtonClick={async () => {
              await handleDeleteExclusionsSubmit();
            }}
            primaryButtonText="Confirm"
            secondaryButtonText="Cancel"
          />
        </Modal.Content.Compose>
      </Modal.Compose>
      <ExclusionTableFilters initialFilterValues={filters} handleSubmit={handleFiltersSubmit} />
      {isExporting ? (
        <>
          <p style={{ textAlign: 'center' }}>Generating export. Please wait...</p>
          <Loader />
        </>
      ) : (
        <>
          <TableControls>
            <ProtectedComponent requiredPermissions={MAINTENANCE_WINDOW_WRITE}>
              <>
                <Button
                  onClick={() => setIsDeleteMultipleModalOpen(true)}
                  style={{ marginLeft: '1rem' }}
                  hidden={!selectedExclusionIdsEligibleForExpiration.length || loading}
                >
                  Expire {selectedExclusionIdsEligibleForExpiration.length} Exclusion
                  {selectedExclusionIdsEligibleForExpiration.length > 1 && 's'}
                </Button>
                <Button
                  onClick={() => setIsBulkEditFormOpen(true)}
                  style={{ marginRight: 'auto', marginLeft: '1rem' }}
                  hidden={!selectedExclusionIds.length || loading}
                >
                  Edit {selectedExclusionIds.length} Exclusion
                  {selectedExclusionIds.length > 1 && 's'}
                </Button>
                <Button onClick={() => onAddClick('cluster')} style={{ marginRight: '1rem' }}>
                  Add Cluster Exclusion
                </Button>
                <Button onClick={() => onAddClick('account')} style={{ marginRight: '1rem ' }}>
                  Add Account Exclusion
                </Button>
              </>
            </ProtectedComponent>
            <ProtectedComponent requiredPermissions={MAINTENANCE_WINDOW_READ}>
              <Button onClick={() => onDownloadClick()} style={{ marginRight: '1rem ' }}>
                Export
              </Button>
            </ProtectedComponent>
          </TableControls>
          <ExclusionTable
            exclusions={exclusions}
            fetchExclusions={fetchExclusions}
            loading={loading}
            totalRecords={totalRecords}
            size={size}
            setSize={setSize}
            filters={filters}
            page={page}
            setPage={setPage}
            onEditClick={onEditClick}
            onDeleteClick={onDeleteClick}
            sort={sort}
            setSort={setSort}
            setSelectedExclusionIds={setSelectedExclusionIds}
            selectedExclusionIds={selectedExclusionIds}
          />
        </>
      )}
    </PaddedTabContainer>
  );
};

export default Exclusions;
