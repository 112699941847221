import { Input, MultiSelect } from '@redislabsdev/redis-ui-components';
import { useState } from 'react';
import { Button } from '@redislabsdev/redislabs-ui-components';
import { ResetIcon } from '@redislabsdev/redis-ui-icons';
import {
  FilterInputsGroup,
  FilterInputsWrapper,
  FilterSectionWrapper,
  SubmitGroup,
} from './TableFilters.style';
import { maintenanceActivityStatusOptions, operationsOptions } from './Select.options';
import { MaintenanceHistoryFilters } from './hooks/useMaintenanceHistory';

type MaintenanceHistoryTableFiltersProps = {
  onSubmit(filters: MaintenanceHistoryFilters): void;
};

const numberRegex = /^[0-9\b]*$/;

const defaultInputState = { operations: [], statuses: [] };

const MaintenanceHistoryTableFilters = ({ onSubmit }: MaintenanceHistoryTableFiltersProps) => {
  const [inputValues, setInputValues] = useState<MaintenanceHistoryFilters>(defaultInputState);
  const [isOperationsDropdownOpen, setIsOperationsDropdownOpen] = useState(false);
  const [isStatusesDropdownOpen, setIsStatusesDropdownOpen] = useState(false);

  const setNumberState = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;
    if (numberRegex.test(value)) {
      setInputValues({
        ...inputValues,
        [evt.target.name]: value !== '' && value !== '0' ? parseInt(value, 10) : undefined,
      });
    }
  };

  const currentOperationsOptions = operationsOptions.map((option) => ({
    ...option,
    checked: inputValues.operations.includes(option.value) || false,
  }));
  const currentStatusesOptions = maintenanceActivityStatusOptions.map((option) => ({
    ...option,
    checked: inputValues.statuses.includes(option.value) || false,
  }));

  return (
    <form
      onSubmit={(evt) => {
        evt.preventDefault();
        onSubmit(inputValues);
      }}
    >
      <FilterSectionWrapper>
        <FilterInputsWrapper>
          <FilterInputsGroup>
            <label htmlFor="subscriptionId">
              Subscription ID
              <Input
                id="subscriptionId"
                name="subscriptionId"
                type="text"
                value={inputValues.subscriptionId?.toString() || ''}
                onChange={setNumberState}
              />
            </label>
          </FilterInputsGroup>
          <FilterInputsGroup>
            <label htmlFor="rcpId">
              RCP ID
              <Input
                id="rcpId"
                name="rcpId"
                type="text"
                value={inputValues.rcpId?.toString() || ''}
                onChange={setNumberState}
              />
            </label>
          </FilterInputsGroup>
          <FilterInputsGroup>
            <label htmlFor="meshId">
              Mesh ID
              <Input
                id="meshId"
                name="meshId"
                type="text"
                value={inputValues.meshId?.toString() || ''}
                onChange={setNumberState}
              />
            </label>
          </FilterInputsGroup>
          <FilterInputsGroup>
            <label htmlFor="accountId">
              Account ID
              <Input
                id="accountId"
                name="accountId"
                type="text"
                value={inputValues.accountId?.toString() || ''}
                onChange={setNumberState}
              />
            </label>
          </FilterInputsGroup>
          <FilterInputsGroup>
            <label htmlFor="operations">
              Operations
              <MultiSelect
                onValueChange={(option, checked) => {
                  setInputValues({
                    ...inputValues,
                    operations: checked
                      ? [...inputValues.operations, option]
                      : inputValues.operations.filter((o) => o !== option),
                  });
                }}
                options={currentOperationsOptions}
                placeholder="Select"
                open={isOperationsDropdownOpen}
                onOpenChange={() => {
                  setIsOperationsDropdownOpen((isOpen) => !isOpen);
                }}
              />
            </label>
          </FilterInputsGroup>
          <FilterInputsGroup>
            <label htmlFor="status">
              Status
              <MultiSelect
                onValueChange={(option, checked) => {
                  setInputValues({
                    ...inputValues,
                    statuses: checked
                      ? [...inputValues.statuses, option]
                      : inputValues.statuses.filter((o) => o !== option),
                  });
                }}
                options={currentStatusesOptions}
                placeholder="Select"
                open={isStatusesDropdownOpen}
                onOpenChange={() => {
                  setIsStatusesDropdownOpen((isOpen) => !isOpen);
                }}
              />
            </label>
          </FilterInputsGroup>
        </FilterInputsWrapper>
        <SubmitGroup>
          <Button
            variant="link"
            onClick={() => {
              setInputValues(defaultInputState);
            }}
          >
            <ResetIcon size="M" />
            <span>Clear All</span>
          </Button>
          <Button variant="secondary" type="submit">
            Apply Filters
          </Button>
        </SubmitGroup>
      </FilterSectionWrapper>
    </form>
  );
};

export default MaintenanceHistoryTableFilters;
