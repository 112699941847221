import omit from 'lodash/omit';
import { addDays, subDays } from 'date-fns';
import { api, buildUrl, defaultApi } from '../../api/config';
import {
  EmailTemplateFormState,
  MaintenanceWindowClustersFilters,
  MaintenanceWindowExclusionsFilters,
  Sort,
} from './MaintenanceWindow.types';

export type MaintenanceWindow = {
  days: number[];
  from: number;
  duration: number;
};

export type Cluster = {
  accountId: number;
  accountName: string;
  rcpId: number | null;
  meshId: number | null;
  clusterId: number | null;
  subscriptionId: number;
  swVersion: string | null;
  optInVersion: string | null;
  optInRequestTime: string | null;
  cloudProviderName: string;
  regionName: string;
  offSm: boolean;
  clusterNotes: string | null;
  nodeCount: number | null;
  clusterUrl: string | null;
  maintenanceMode: boolean;
  maintenanceWindows: MaintenanceWindow[];
  lastMaintenanceActivity?: {
    timestamp: string;
    operations: string[];
    includeSystemLog: boolean;
    includeEmail: boolean;
    setMaintenanceMode: boolean;
    sendgridId: string;
    internalNotes: string;
  } | null;
  excludedOperations: string[];
  accountAdvanceNotification: boolean;
  advanceNotification: boolean;
  scheduledMaintenanceWindowStart: string | null;
  scheduledMaintenanceWindowEnd: string | null;
  availableScheduledMaintenanceHours: number | null;
};

export type Exclusion = {
  exclusionId: number;
  accountId: number;
  accountName: string;
  rcpId: number | null;
  meshId: number | null;
  subscriptionId: number;
  start: string;
  end: string;
  operations: string[];
  reason: string | null;
  initiator: 'Ops' | 'Customer';
  isAccountScoped: boolean;
};

export type ClustersResponse = {
  records: Cluster[];
  totalRecords: number;
};

export type EmailTemplatesResponse = {
  email_template_id: number;
  friendly_name: string;
  sendgrid_id: string;
  maintenance_type: string;
  is_urgent: boolean;
};

export type FilterOptionsResponse = {
  regions: {
    id: number;
    name: string;
    cloudId: number;
  }[];
  cloudProviders: {
    id: number;
    name: string;
  }[];
};

export type MaintenanceEvent = {
  rcpId?: string | null;
  meshId?: string | null;
  maintenanceType: string;
  operations: string[];
  isUrgent: boolean;
  includeSystemLog: boolean;
  includeEmail: boolean;
  setMaintenanceMode: boolean;
  internalNotes?: string | null;
  emailTemplate?: string;
  bypassMaintenanceWindow?: boolean;
  bypassExclusions?: boolean;
};

export type ExclusionsResponse = {
  records: Exclusion[];
  totalRecords: number;
};

export type AddExclusionRequest = {
  rcpId?: number;
  meshId?: number;
  accountId?: number;
  operations: string[];
  reason?: string;
  start: string;
  end: string;
};

export type EditExclusionRequest = {
  exclusionId: number;
  operations: string[];
  reason?: string;
  start: string;
  end: string;
};

export type EditExclusionsRequest = {
  exclusionIds: number[];
  payload: {
    operations?: ('optimization' | 'upgrade')[];
    reason?: string;
    endDate?: string;
  };
};

const exclusionsArrayFilterFields = ['operations'];

export const buildArrayFilters = (
  filters: MaintenanceWindowClustersFilters | MaintenanceWindowExclusionsFilters,
  arrayFilterFields: string[]
) => {
  const filterString = Object.keys(filters)
    .filter((key) => arrayFilterFields.includes(key) && filters[key].length)
    .reduce((all, key) => {
      const prefix = all ? '&' : '?';

      const values = filters[key].map((value) => `${key}[]=${encodeURIComponent(value)}`);
      return `${all}${prefix}${values.join('&')}`;
    }, '');

  return filterString;
};

export const blankFilters = (
  filters: MaintenanceWindowClustersFilters | MaintenanceWindowExclusionsFilters
) =>
  Object.entries(filters)
    .filter(([_, value]) => value === 'blank')
    .map(([key]) => key);

export const createMaintenanceEventRequest = (values: MaintenanceEvent) =>
  api.post(`${buildUrl('maintenanceWindow')}/maintenance-activity`, values);

export const getEmailTemplatesRequest = () =>
  api.get<EmailTemplatesResponse[]>(`${buildUrl('maintenanceWindow')}/email-templates`);

export const createEmailTemplateRequest = (values: EmailTemplateFormState) =>
  api.post(`${buildUrl('maintenanceWindow')}/email-template`, values);

export const updateEmailTemplateRequest = (values: EmailTemplateFormState) =>
  api.put(
    `${buildUrl('maintenanceWindow')}/email-template/${values.email_template_id}`,
    omit(values, 'email_template_id')
  );

export const deleteEmailTemplateRequest = (email_template_id: number) =>
  api.delete(`${buildUrl('maintenanceWindow')}/email-template/${email_template_id}`);

export const getExclusionTableDataApiRequest = (
  page: number,
  size: number,
  filters: MaintenanceWindowExclusionsFilters,
  sort: Sort | null
) =>
  api
    .get<ExclusionsResponse>(
      `${buildUrl('maintenanceWindow')}/exclusions${buildArrayFilters(
        filters,
        exclusionsArrayFilterFields
      )}`,
      {
        params: {
          page,
          size,
          ...omit(filters, exclusionsArrayFilterFields, blankFilters(filters)),
          ...sort,
        },
      }
    )
    .then((response) => {
      response.data.records = response.data.records.map((exclusion) => ({
        ...exclusion,
        end:
          exclusion.initiator === 'Ops'
            ? subDays(new Date(exclusion.end), 7).toISOString()
            : exclusion.end,
      }));
      return response;
    });

export const updateClusterNotes = (clusterId: number, clusterNotes: string) =>
  api.put(`${buildUrl('maintenanceWindow')}/clusters/${clusterId}/notes`, {
    clusterNotes,
  });

export const addExclusionRequest = (exclusion: AddExclusionRequest) =>
  api.post(`${buildUrl('maintenanceWindow')}/exclusions`, {
    ...exclusion,
    // API uses true end dates, not pending ones
    end: addDays(new Date(exclusion.end), 7).toISOString(),
  });

export const editExclusionRequest = ({ exclusionId, ...exclusion }: EditExclusionRequest) =>
  api.put(`${buildUrl('maintenanceWindow')}/exclusions/${exclusionId}`, {
    ...exclusion,
    // API uses true end dates, not pending ones
    end: addDays(new Date(exclusion.end), 7).toISOString(),
  });

export const editExclusionsRequest = (request: EditExclusionsRequest) => {
  const endDate = request.payload.endDate;
  const adjustedRequest: EditExclusionsRequest = endDate
    ? {
        ...request,
        payload: {
          ...request.payload,
          endDate: addDays(new Date(endDate), 7).toISOString(),
        },
      }
    : request;
  return api.put(`${buildUrl('maintenanceWindow')}/exclusions`, adjustedRequest);
};

export const expireExclusionsRequest = (exclusionIds: number[]) =>
  defaultApi.post(`${buildUrl('maintenanceWindow')}/exclusions/expire`, {
    exclusionIds,
  });

export const getFilterOptions = () =>
  api.get<FilterOptionsResponse>(`${buildUrl('maintenanceWindow')}/cluster-filter-options`);

export const updateAccountAdvanceNotification = (accountId: number, advanceNotification: boolean) =>
  api.put(`${buildUrl('maintenanceWindow')}/accounts/${accountId}/advance-notification`, {
    advanceNotification,
  });
