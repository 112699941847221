import React from 'react';
import classNames from 'classnames';
import { ArrowDownSlimIcon, ArrowUpSlimIcon } from '@redislabsdev/redis-ui-icons';
import OptionallyProtectedComponent from '../../components/OptionallyProtectedComponent/OptionallyProtectedComponent';
import { StyledTable, TableContainer } from './Table.style';

export type TableProps<T> = {
  columns: {
    header?: string | (() => JSX.Element | string | null);
    headerKey?: string;
    render?: keyof T | ((row: T) => JSX.Element | string | null);
    sortKey?: string;
    cellComponent?: React.FC;
    protectedBy?: string;
  }[];
  data: T[];
  sortKey?: string;
  sortDirection?: 'asc' | 'desc';
  onSort?(x: { sortKey: string; sortDirection: 'asc' | 'desc' }): void;
  noDataText?: string;
  rowComponent?: React.FC;
  rowKey: keyof T | ((row: T) => string | number);
};

const Table = <T,>({
  columns,
  data,
  sortKey: tableSortKey,
  sortDirection,
  onSort,
  noDataText,
  rowComponent,
  rowKey,
}: TableProps<T>) => {
  return (
    <TableContainer>
      <StyledTable>
        <thead>
          <tr>
            {columns.map(({ header, headerKey, sortKey, protectedBy }) => {
              let content: JSX.Element | string | null = null;
              if (typeof header === 'string') {
                content = header;
              } else if (typeof header === 'function') {
                content = header();
              }

              const sorted = tableSortKey === sortKey;
              const showDescendingArrow = sorted && sortDirection === 'desc';
              const arrow = showDescendingArrow ? (
                <ArrowDownSlimIcon size="M" />
              ) : (
                <ArrowUpSlimIcon size="M" />
              );

              const key = headerKey || header;
              if (typeof key !== 'string') {
                throw new Error(
                  'No usable key for column. Provide headerKey or header value for column.'
                );
              }

              return (
                <OptionallyProtectedComponent requiredPermissions={protectedBy} key={key}>
                  <th
                    className={classNames({ sortable: !!sortKey, sorted })}
                    onClick={
                      !!sortKey && onSort
                        ? () => {
                            onSort({
                              sortKey,
                              sortDirection: sorted && sortDirection === 'asc' ? 'desc' : 'asc',
                            });
                          }
                        : undefined
                    }
                  >
                    <div>
                      <div>{content}</div>
                      {!!sortKey && <div>{arrow}</div>}
                    </div>
                  </th>
                </OptionallyProtectedComponent>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {!data.length && (
            <tr>
              <td colSpan={columns.length} style={{ textAlign: 'center' }}>
                {noDataText || 'No results found.'}
              </td>
            </tr>
          )}
          {data.map((row) => {
            const Row = rowComponent || 'tr';
            let key: string | number | null = null;
            if (typeof rowKey === 'string') {
              key = row[rowKey as keyof T] as string | number;
            } else if (typeof rowKey === 'function') {
              key = rowKey(row);
            }
            return (
              <Row key={key} {...(rowComponent && row)}>
                {columns.map((col) => {
                  let value: unknown = '';
                  if (typeof col.render === 'string') {
                    value = row[col.render];
                  } else if (typeof col.render === 'function') {
                    value = col.render(row);
                  }

                  const Cell = col.cellComponent || 'td';
                  const colKey = (col.headerKey || col.header) as string;

                  return (
                    <OptionallyProtectedComponent
                      requiredPermissions={col.protectedBy}
                      key={colKey}
                    >
                      <Cell {...(col.cellComponent && row)}>{value}</Cell>
                    </OptionallyProtectedComponent>
                  );
                })}
              </Row>
            );
          })}
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};

export default Table;
