import React, { useState } from 'react';
import omit from 'lodash/omit';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import {
  DateRange,
  Input,
  Popover,
  RangeDatePicker,
  IconButton,
  MultiSelect,
} from '@redislabsdev/redis-ui-components';
import { Button, SingleSelectorDropDown, showToast } from '@redislabsdev/redislabs-ui-components';
import { CalendarIcon, CancelIcon, ResetIcon } from '@redislabsdev/redis-ui-icons';
import * as CS from '../../styles/common.style';
import {
  TimeRangeRow,
  DateButtonRow,
  FilterSectionWrapper,
  FilterInputsGroup,
  FilterInputsWrapper,
  SubmitGroup,
} from './TableFilters.style';
import { MaintenanceWindowExclusionsFilters } from './MaintenanceWindow.types';
import {
  blankOption,
  initiatorOptions,
  timeOptions,
  excludedOperationsOptions,
  booleanOptions,
  exclusionExpiredStatusOptions,
} from './Select.options';
import getDefaultDropDownOption from './getDefaultDropDownOption';

const displayFormattedDateTime = (displayedDate) =>
  moment.utc(displayedDate).format('DD/MM/YYYY hhA');

const defaultStartTime = '12:00 AM';
const defaultEndTime = '11:00 PM';

const numberRegex = /^[0-9\b]+$/;

type TableFiltersProps = {
  initialFilterValues: MaintenanceWindowExclusionsFilters;
  handleSubmit: (values: MaintenanceWindowExclusionsFilters) => void;
};

const ExclusionTableFilters: React.FC<TableFiltersProps> = ({
  initialFilterValues,
  handleSubmit,
}) => {
  const [filters, setFilters] = useState<MaintenanceWindowExclusionsFilters>(initialFilterValues);

  // select
  const [defaultSelectValues, setDefaultSelectValues] = useState({
    initiator: getDefaultDropDownOption(initialFilterValues.initiator, initiatorOptions),
    accountScoped: getDefaultDropDownOption(initialFilterValues.accountScoped, booleanOptions),
    exclusionExpiredStatus: getDefaultDropDownOption(
      initialFilterValues.exclusionExpiredStatus,
      exclusionExpiredStatusOptions
    ),
  });

  // multi-select
  const currentOperationsOptions = excludedOperationsOptions.map((option) => ({
    ...option,
    checked: filters.operations?.includes(option.value) || false,
  }));

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, regex?: RegExp) => {
    if (!regex) {
      handleFilterChange(event.target.name, event.target.value);
      return;
    }

    if (event.target.value === '' || regex.test(event.target.value)) {
      handleFilterChange(event.target.name, event.target.value);
    }
  };

  const [startTime, setStartTime] = useState<string>(defaultStartTime);
  const [endTime, setEndTime] = useState<string>(defaultEndTime);
  const [dateRange, setDateRange] = useState<DateRange>();
  const [showDatePopover, setShowDatePopover] = useState(false);
  const [isOperationsDropdownOpen, setIsOperationsDropdownOpen] = useState(false);

  const handleFilterChange = (fieldName, value) => {
    if (!value || (isArray(value) && isEmpty(value))) {
      setFilters((prevState) => omit(prevState, fieldName));
    } else {
      setFilters((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(filters);
      }}
    >
      <FilterSectionWrapper data-testid="mw-filter-section">
        <FilterInputsWrapper>
          <FilterInputsGroup>
            <label htmlFor="rcpID">
              RCP ID
              <Input
                type="text"
                name="rcpID"
                id="rcpID"
                value={filters.rcpID || ''}
                onChange={(e) => onChangeHandler(e, numberRegex)}
              />
            </label>
          </FilterInputsGroup>

          <FilterInputsGroup>
            <label htmlFor="meshID">
              Mesh ID
              <Input
                type="text"
                name="meshID"
                id="meshID"
                value={filters.meshID || ''}
                onChange={(e) => onChangeHandler(e, numberRegex)}
              />
            </label>
          </FilterInputsGroup>

          <FilterInputsGroup>
            <label htmlFor="subscriptionID">
              Subscription ID
              <Input
                type="text"
                name="subscriptionID"
                id="subscriptionID"
                value={filters.subscriptionID || ''}
                onChange={(e) => onChangeHandler(e, numberRegex)}
              />
            </label>
          </FilterInputsGroup>

          <FilterInputsGroup>
            <label htmlFor="accountID">
              Account ID
              <Input
                type="text"
                name="accountID"
                id="accountID"
                value={filters.accountID || ''}
                onChange={(e) => onChangeHandler(e, numberRegex)}
              />
            </label>
          </FilterInputsGroup>

          <FilterInputsGroup>
            <label htmlFor="Account Name">
              Account Name
              <Input
                type="text"
                name="accountName"
                id="accountName"
                value={filters.accountName || ''}
                onChange={onChangeHandler}
              />
            </label>
          </FilterInputsGroup>

          <FilterInputsGroup>
            <label htmlFor="operations">
              Operations
              <MultiSelect
                onValueChange={(option, checked) => {
                  const newOptions = currentOperationsOptions.map((operationOption) => {
                    if (operationOption.value === option) {
                      return {
                        ...operationOption,
                        checked,
                      };
                    }
                    return operationOption;
                  });

                  const selectedValues = newOptions
                    .filter(({ checked }) => checked)
                    .map(({ value }) => value);
                  handleFilterChange('operations', selectedValues);
                }}
                options={currentOperationsOptions}
                placeholder="Select"
                open={isOperationsDropdownOpen}
                onOpenChange={() => {
                  setIsOperationsDropdownOpen((isOpen) => !isOpen);
                }}
              />
            </label>
          </FilterInputsGroup>

          <FilterInputsGroup>
            <label htmlFor="reason">
              Exclusion Reason
              <Input
                type="text"
                name="reason"
                id="reason"
                value={filters.reason || ''}
                onChange={onChangeHandler}
              />
            </label>
          </FilterInputsGroup>

          <FilterInputsGroup>
            <label htmlFor="initiator">
              Initiator
              <SingleSelectorDropDown
                data-role="dropdown-button"
                optionList={initiatorOptions}
                reset={!filters.initiator}
                defaultValue={defaultSelectValues.initiator}
                borderRadius
                borderColor="gray1"
                headerBorder
                getSelectedOption={(item) => {
                  handleFilterChange('initiator', `${item?.id}`);
                }}
              />
            </label>
          </FilterInputsGroup>

          <FilterInputsGroup>
            <label htmlFor="accountScoped">
              Account Scoped
              <SingleSelectorDropDown
                data-role="dropdown-button"
                optionList={booleanOptions}
                reset={!filters.accountScoped}
                defaultValue={defaultSelectValues.accountScoped}
                borderRadius
                borderColor="gray1"
                headerBorder
                getSelectedOption={(item) => {
                  handleFilterChange('accountScoped', `${item?.id}`);
                }}
              />
            </label>
          </FilterInputsGroup>

          <FilterInputsGroup className="date-picker">
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="startDate">
              <span>Date</span>
              <CS.SpanWithDate useAsRange>
                <span data-testid="textinput--start-date">
                  {filters.start
                    ? `${displayFormattedDateTime(filters.start)}
                        - ${displayFormattedDateTime(filters.end)}`
                    : ''}
                </span>
                <CS.SpanWithDateButtonsWrapper>
                  {filters.start ? (
                    <Button
                      data-testid="button--clear-start-date"
                      variant="secondary"
                      size="small"
                      onClick={() => {
                        handleFilterChange('start', '');
                        handleFilterChange('end', '');
                      }}
                    >
                      <CancelIcon size="L" />
                    </Button>
                  ) : (
                    <Popover.Compose
                      visible={showDatePopover}
                      onVisibilityChange={setShowDatePopover}
                    >
                      <Popover.Trigger>
                        <IconButton data-testid="button--start-date" icon={CalendarIcon} />
                      </Popover.Trigger>
                      <CS.DualDatePickerPopover placement="bottom">
                        <CS.DualDatePickerPopoverBody style={{ flexDirection: 'column' }}>
                          <RangeDatePicker
                            selectedRange={dateRange}
                            onRangeSelect={(selectedRange) => {
                              setDateRange(selectedRange);
                            }}
                          />
                          <TimeRangeRow>
                            <div>
                              <label htmlFor="fromTime">
                                From
                                <SingleSelectorDropDown
                                  data-role="dropdown-button"
                                  optionList={timeOptions}
                                  defaultValue={{
                                    id: defaultStartTime,
                                    title: defaultStartTime,
                                  }}
                                  reset={startTime === defaultStartTime}
                                  borderRadius
                                  borderColor="gray1"
                                  headerBorder
                                  getSelectedOption={(item) => {
                                    setStartTime(`${item?.id}`);
                                  }}
                                />
                              </label>
                            </div>
                            <div>
                              <label htmlFor="toTime">
                                To
                                <SingleSelectorDropDown
                                  data-role="dropdown-button"
                                  optionList={timeOptions}
                                  defaultValue={{
                                    id: defaultEndTime,
                                    title: defaultEndTime,
                                  }}
                                  reset={endTime === defaultEndTime}
                                  borderRadius
                                  borderColor="gray1"
                                  headerBorder
                                  getSelectedOption={(item) => {
                                    setEndTime(`${item?.id}`);
                                  }}
                                />
                              </label>
                            </div>
                          </TimeRangeRow>
                          <DateButtonRow>
                            <Button
                              variant="secondary"
                              onClick={() => {
                                setShowDatePopover(false);
                              }}
                            >
                              Cancel
                            </Button>{' '}
                            <Button
                              variant="secondary"
                              onClick={() => {
                                setDateRange(undefined);
                                setStartTime(defaultStartTime);
                                setEndTime(defaultEndTime);
                              }}
                            >
                              Reset
                            </Button>{' '}
                            <Button
                              variant="primary"
                              onClick={() => {
                                const start = moment.utc(
                                  moment(dateRange?.from).utcOffset(0, true).format('YYYY-MM-DD') +
                                    ' ' +
                                    startTime,
                                  'YYYY-MM-DD hh:mm A'
                                );
                                const end = moment.utc(
                                  moment(dateRange?.to || dateRange?.from)
                                    .utcOffset(0, true)
                                    .format('YYYY-MM-DD') +
                                    ' ' +
                                    endTime,
                                  'YYYY-MM-DD hh:mm A'
                                );

                                if (start.unix() > end.unix()) {
                                  showToast('Start Date is greater than End Date');
                                } else {
                                  handleFilterChange('start', start.format('YYYY-MM-DD HH:mm:ss'));
                                  handleFilterChange('end', end.format('YYYY-MM-DD HH:mm:ss'));

                                  setShowDatePopover(false);
                                }
                              }}
                            >
                              Apply
                            </Button>
                          </DateButtonRow>
                        </CS.DualDatePickerPopoverBody>
                      </CS.DualDatePickerPopover>
                    </Popover.Compose>
                  )}
                </CS.SpanWithDateButtonsWrapper>
              </CS.SpanWithDate>
            </label>
          </FilterInputsGroup>

          <FilterInputsGroup>
            <label htmlFor="exclusionExpiredStatus">
              Exclusion Expiration Status
              <SingleSelectorDropDown
                data-role="dropdown-button"
                optionList={exclusionExpiredStatusOptions}
                reset={!filters.exclusionExpiredStatus}
                defaultValue={defaultSelectValues.exclusionExpiredStatus}
                borderRadius
                borderColor="gray1"
                headerBorder
                getSelectedOption={(item) => {
                  handleFilterChange('exclusionExpiredStatus', `${item?.id}`);
                }}
              />
            </label>
          </FilterInputsGroup>
        </FilterInputsWrapper>
        <SubmitGroup>
          <Button
            variant="link"
            onClick={() => {
              setFilters({});
              setDefaultSelectValues({
                initiator: blankOption,
                accountScoped: blankOption,
                exclusionExpiredStatus: defaultSelectValues.exclusionExpiredStatus,
              });
            }}
          >
            <ResetIcon size="M" />
            <span>Clear All</span>
          </Button>
          <Button variant="secondary" type="submit">
            Apply Filters
          </Button>
        </SubmitGroup>
      </FilterSectionWrapper>
    </form>
  );
};

export default ExclusionTableFilters;
