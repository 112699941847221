import { parseISO } from 'date-fns';
import { Tooltip } from '@redislabsdev/redislabs-ui-components';
import React from 'react';
import formatInTimeZone from './formatInTimeZone';

const dateFormat = 'd-MMM-y';
const timeFormat = 'HH:mm';
const dateTimeFormat = `${dateFormat} ${timeFormat}`;

export const formatScheduledMaintenanceWindow = (
  windowStart: string,
  windowEnd: string,
  availableHours: number
) => {
  const parsedStart = parseISO(windowStart);
  const parsedEnd = parseISO(windowEnd);

  let formattedString: string;
  if (
    formatInTimeZone(parsedStart, dateFormat, 'UTC') ===
    formatInTimeZone(parsedEnd, dateFormat, 'UTC')
  ) {
    formattedString = `${formatInTimeZone(parsedStart, dateTimeFormat, 'UTC')}-${formatInTimeZone(
      parsedEnd,
      timeFormat,
      'UTC'
    )}`;
  } else {
    formattedString = `${formatInTimeZone(parsedStart, dateTimeFormat, 'UTC')}-${formatInTimeZone(
      parsedEnd,
      dateTimeFormat,
      'UTC'
    )}`;
  }

  if (availableHours > 1) {
    return formattedString;
  }

  return (
    <Tooltip
      textColor="#01112a"
      tooltipContent={`This scheduled maintenance no longer sufficiently overlaps with the customer's configured maintenance windows. Hours available: ${availableHours}`}
    >
      <p style={{ color: 'red' }}>{formattedString}</p>
    </Tooltip>
  );
};
