import { useQuery } from 'react-query';
import omitBy from 'lodash/omitBy';
import { defaultApi, buildUrl } from '../../../api/config';

export type MaintenanceHistoryRecord = {
  subscriptionId: number;
  accountId: number;
  accountName: string;
  rcpId: number;
  meshId: number;
  maintenanceStartTime: string;
  maintenanceEndTime: string;
  operations: string[];
  maintenanceWindows: {
    days: number[];
    from: number;
    duration: number;
  }[];
  exceedingWindow: boolean;
};

export type MaintenanceHistorySortBy =
  | 'status'
  | 'subscriptionId'
  | 'rcpId'
  | 'meshId'
  | 'accountId';
export type MaintenanceHistorySortDirection = 'asc' | 'desc';

export type MaintenanceHistoryFilters = {
  subscriptionId?: number;
  rcpId?: number;
  meshId?: number;
  accountId?: number;
  operations: string[];
  statuses: string[];
};

export const useMaintenanceHistory = (
  params: {
    page: number;
    size: number;
    sortBy: MaintenanceHistorySortBy;
    sortDirection: MaintenanceHistorySortDirection;
  } & MaintenanceHistoryFilters
) => {
  const keys = Object.keys(params);
  keys.sort();

  const query = useQuery({
    queryKey: ['maintenance-history', ...keys.map((k) => `${k}:${params[k]}`)],
    queryFn: async () => {
      const { data } = await defaultApi.get<{
        records: MaintenanceHistoryRecord[];
        totalRecords: number;
      }>(`${buildUrl('maintenanceWindow')}/maintenance-history`, {
        params: {
          ...omitBy(params, (p) => p === undefined),
        },
      });
      return data;
    },
    refetchOnWindowFocus: true,
    refetchInterval: 60 * 1000,
  });
  return query;
};
