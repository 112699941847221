import { Button, Modal } from '@redislabsdev/redis-ui-components';
import { Cluster } from './MaintenanceWindowPage.api';

type Props = {
  cluster?: Cluster | null;
  onSubmit: () => void;
  onCancel: () => void;
};

export const AdvanceNotificationConfirmModal = ({ onSubmit, onCancel, cluster }: Props) => {
  if (!cluster) {
    return null;
  }

  const { accountId, accountAdvanceNotification } = cluster;

  return (
    <Modal.Compose open={true}>
      <Modal.Content.Compose>
        <Modal.Content.Header title="Require Advance Notification"></Modal.Content.Header>
        <Modal.Content.Body.Compose>
          <p style={{ marginBottom: '1rem' }}>
            Toggle advance notification requirement{' '}
            <span style={{ fontWeight: 'bold' }}>{accountAdvanceNotification ? 'off' : 'on'}</span>?
          </p>
          <p style={{ marginBottom: '1rem' }}>
            This will apply to all subscriptions associated with the account.
          </p>
          <p>Account ID: {accountId}</p>
        </Modal.Content.Body.Compose>
        <Modal.Content.Footer.Compose style={{ justifyContent: 'right' }}>
          <Button
            type="button"
            variant="secondary-ghost"
            size="large"
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button type="submit" size="large" style={{ marginLeft: '.5rem' }} onClick={onSubmit}>
            Submit
          </Button>
        </Modal.Content.Footer.Compose>
      </Modal.Content.Compose>
    </Modal.Compose>
  );
};
