import React, { useMemo, useState } from 'react';
import { Formik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import ScheduledMaintenanceForm, {
  AdvancedNotificationsFormState,
  AdvanceNotificationRouterParams,
} from './ScheduledMaintenanceForm';
import { createScheduledMaintenanceRequest } from './ScheduledMaintenance.api';
import { ErrorComponent } from './MaintenanceWindowPage.style';

const ScheduledMaintenanceFormWrapper = () => {
  const { subscriptionId, subscriptionType } = useParams<AdvanceNotificationRouterParams>();
  const history = useHistory();
  const [error, setError] = useState<string | undefined>();
  const initialFormState: AdvancedNotificationsFormState = useMemo(
    () => ({
      subscriptionId: parseInt(subscriptionId),
      subscriptionType,
      emailTemplate: '',
      maintenanceDate: '',
      operations: [],
    }),
    [subscriptionId]
  );

  return (
    <Formik
      initialValues={initialFormState}
      onSubmit={(values, actions) => {
        setError(undefined);

        let anyError = false;
        if (!values.emailTemplate) {
          anyError = true;
          actions.setErrors({
            emailTemplate: 'Email template is required.',
          });
        }
        if (!values.maintenanceDate || !values.subscriptionCustomWindowId) {
          anyError = true;
          actions.setErrors({
            maintenanceDate: 'Valid maintenance date and time is required.',
          });
        }
        if (anyError) {
          actions.setSubmitting(false);
          return;
        }

        return createScheduledMaintenanceRequest(values)
          .then(() => {
            history.push('/maintenanceWindow/advanceNotifications');
          })
          .catch((e) => {
            const errorMessage =
              e.response?.data?.error?.message || 'Error creating advance notification';
            setError(errorMessage);
            actions.setSubmitting(false);
          });
      }}
    >
      <>
        {error && <ErrorComponent>{error}</ErrorComponent>}
        <ScheduledMaintenanceForm />
      </>
    </Formik>
  );
};

export default ScheduledMaintenanceFormWrapper;
