import { TablePagination } from '@redislabsdev/redislabs-ui-components/ui/components/Table';
import { Tooltip } from '@redislabsdev/redislabs-ui-components';
import { parseISO } from 'date-fns';
import { NoBreakCell } from './Table.style';
import {
  MaintenanceHistoryRecord,
  MaintenanceHistorySortBy,
  MaintenanceHistorySortDirection,
} from './hooks/useMaintenanceHistory';
import { daysLookup } from './daysLookup';
import MaintenanceWindowsTooltip from './MaintenanceWindowsTooltip';
import formatInTimeZone from './formatInTimeZone';
import Table, { TableProps } from './Table';
import * as S from './MaintenanceHistory.style';

const dateFormat = 'd-MMM-y';
const timeFormat = 'HH:mm';
const dateTimeFormat = `${dateFormat} ${timeFormat}`;

const dateTimeFormatter = (field: 'maintenanceStartTime' | 'maintenanceEndTime') => (
  row: MaintenanceHistoryRecord
) => {
  const value = row[field];
  if (value) {
    return <>{formatInTimeZone(parseISO(row[field]), dateTimeFormat, 'UTC')}</>;
  }
  return null;
};

const columns: TableProps<MaintenanceHistoryRecord>['columns'] = [
  {
    header: 'Subscription ID',
    render: 'subscriptionId',
    sortKey: 'subscriptionId',
    cellComponent: NoBreakCell,
  },
  {
    header: 'RCP ID',
    render: 'rcpId',
    sortKey: 'rcpId',
    cellComponent: NoBreakCell,
  },
  {
    header: 'Mesh ID',
    render: 'meshId',
    sortKey: 'meshId',
    cellComponent: NoBreakCell,
  },
  {
    header: 'Account ID',
    render: 'accountId',
    sortKey: 'accountId',
    cellComponent: NoBreakCell,
  },
  {
    header: 'Account Name',
    render: 'accountName',
    cellComponent: NoBreakCell,
  },
  {
    header: 'Maintenance Start',
    render: dateTimeFormatter('maintenanceStartTime'),
  },
  {
    header: 'Maintenance End',
    render: dateTimeFormatter('maintenanceEndTime'),
  },
  {
    header: 'Operations',
    render: (row) => {
      return <>{row.operations?.join(', ')}</>;
    },
  },
  {
    header: 'Maintenance Windows',
    render: ({ maintenanceWindows: windows }) => {
      if (!windows.length) {
        return null;
      }
      const additionalWindows = windows.length > 1 ? `+${windows.length - 1}` : '';
      const cell = `${daysLookup[windows[0].days[0]]}… ${additionalWindows}`;
      return (
        <Tooltip
          tooltipContent={<MaintenanceWindowsTooltip windows={windows} />}
          textColor="#01112a"
        >
          {cell}
        </Tooltip>
      );
    },
  },
  {
    header: 'Status',
    sortKey: 'status',
    render: ({ maintenanceEndTime }) => {
      return <>{!maintenanceEndTime ? 'In Progress' : 'Finished'}</>;
    },
  },
];

type MaintenanceHistoryProps = {
  page: number;
  onSetPage(pageIndex: number): void;
  size: number;
  onSetSize(size: number): void;
  sortBy: MaintenanceHistorySortBy;
  sortDirection: MaintenanceHistorySortDirection;
  onSort(sortBy: MaintenanceHistorySortBy, sortDirection: MaintenanceHistorySortDirection): void;
  rows: MaintenanceHistoryRecord[];
  totalRecords: number;
};

const MaintenanceHistoryTable = ({
  page,
  size,
  rows,
  totalRecords,
  onSetPage,
  onSetSize,
  sortBy,
  sortDirection,
  onSort,
}: MaintenanceHistoryProps) => {
  const pageCount = Math.ceil(totalRecords / size);

  return (
    <div>
      <Table
        data={rows}
        columns={columns}
        sortKey={sortBy}
        sortDirection={sortDirection}
        onSort={({ sortKey, sortDirection }) =>
          onSort(sortKey as MaintenanceHistorySortBy, sortDirection)
        }
        rowComponent={S.Tr}
        rowKey={(row) => `${row.subscriptionId}-${row.maintenanceStartTime}`}
      />
      <TablePagination
        isPaginatedControlled
        rows={rows}
        canNextPage={page + 1 < pageCount}
        canPreviousPage={page > 0}
        pageIndex={page}
        pageCount={pageCount}
        pageSize={size}
        gotoPage={(pageIndex: number) => {
          onSetPage(pageIndex);
        }}
        nextPage={() => {
          onSetPage(page + 1);
        }}
        previousPage={() => {
          onSetPage(page - 1);
        }}
        setPageSize={(newSize: number) => {
          onSetSize(newSize);
        }}
      />
    </div>
  );
};

export default MaintenanceHistoryTable;
