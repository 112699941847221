import styled from 'styled-components/macro';

export const ExtendPopupContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 1rem;

  & .DayPicker-Months div[data-role='selection-area'] {
    color: gray;
    pointer-events: none;
  }
`;

export const UpdateEndDateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eaedf2;
  margin-bottom: 1rem;
`;

export const EndDateSpan = styled.span`
  font-size: 1.8rem;
  color: #253dac;
`;
