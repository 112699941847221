import React, { useMemo, useState } from 'react';
import { Formik } from 'formik';
import { Modal } from '@redislabsdev/redis-ui-components';
import { Loader } from '@redislabsdev/redislabs-ui-components';
import { useLocation } from 'react-router-dom';
import { parseISO } from 'date-fns';
import { showToast } from '../../components/Toast/Toast';
import formatInTimeZone from './formatInTimeZone';
import MaintenanceActivityForm from './MaintenanceActivityForm';
import { MaintenanceEventFormState } from './MaintenanceWindow.types';
import { createMaintenanceEventRequest, MaintenanceEvent } from './MaintenanceWindowPage.api';
import { ErrorComponent } from './MaintenanceWindowPage.style';

type ErrorObject = {
  code: string;
  message: string;
  details?: {
    exclusions?: {
      exclusion_id: number;
      ops_initiated: boolean;
      start_date: string;
      end_date: string;
      reason: string;
      operations: string[];
    }[];
    maintenance_date?: string;
    minutes_away_from_schedule?: number;
    permissible_minutes_away_from_schedule?: number;
    providedOperations?: string[];
    scheduledOperations?: string[];
  };
};

const MaintenanceActivityFormWrapper = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<ErrorObject[] | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [requestPayload, setRequestPayload] = useState<MaintenanceEvent | null>(null);
  const [clusterIdentifier, setClusterIdentifier] = useState<string>('');
  const [modalText, setModalText] = useState<JSX.Element | string>('');

  const queryParams = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    return {
      rcp_id: urlParams.get('rcp_id'),
      mesh_id: urlParams.get('mesh_id'),
    };
  }, [location]);

  const initialFormValues: MaintenanceEventFormState = useMemo(() => {
    const rcpId = queryParams.rcp_id ? (queryParams.rcp_id as string) : '';
    const meshId = queryParams.mesh_id ? (queryParams.mesh_id as string) : '';
    return {
      maintenanceType: 'start',
      rcpId,
      meshId,
      operations: [],
      includeSystemLog: true,
      includeEmail: true,
      setMaintenanceMode: true,
      internalNotes: '',
      emailTemplate: '',
      isUrgent: false,
      bypassMaintenanceWindow: false,
      bypassExclusions: false,
    };
  }, [queryParams]);

  const handleSubmitModal = () => {
    if (!requestPayload) {
      return;
    }
    setIsLoading(true);
    setErrors(null);
    setIsModalOpen(false);
    createMaintenanceEventRequest(requestPayload)
      .then(() => {
        showToast('Maintenance activity submitted successfully.', 'success');
      })
      .catch((e) => {
        const errors = e.response.data?.errors || [{ message: 'Unexpected error occurred.' }];
        setErrors(errors);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading && (
        <div data-testid="mw-fetching-indicator">
          <Loader />
        </div>
      )}
      {errors && (
        <ErrorComponent>
          {errors.length === 1 ? 'An error occurred:' : 'Multiple errors occurred:'}
          <ul>
            {errors.map((error, index) => {
              if (error.code === 'active-exclusion') {
                return (
                  <li key={index}>
                    {error.message}
                    <ul>
                      {error.details?.exclusions?.map((exclusion, exclusionIndex) => (
                        <li key={exclusionIndex}>
                          {exclusion.ops_initiated ? 'Ops-initiated' : 'Customer-initiated'}{' '}
                          exclusion #{exclusion.exclusion_id}, from {exclusion.start_date} to{' '}
                          {exclusion.end_date}. Operations: {exclusion.operations.join(', ')}.
                          {exclusion.reason ? ` Reason: ${exclusion.reason}` : ''}
                        </li>
                      ))}
                    </ul>
                  </li>
                );
              }

              if (
                error.code === 'outside-scheduled-maintenance' &&
                error.details?.maintenance_date
              ) {
                const parsed = parseISO(error.details?.maintenance_date);
                return (
                  <li key={index}>
                    {error.message}
                    <ul>
                      <li>
                        Scheduled maintenance: {formatInTimeZone(parsed, 'd-MMM-y HH:mm:ss', 'UTC')}
                        . Current time is {error.details?.minutes_away_from_schedule} minute
                        {error.details?.minutes_away_from_schedule === 1 ? '' : 's'} away from
                        scheduled time, allowed value is{' '}
                        {error.details?.permissible_minutes_away_from_schedule}.
                      </li>
                    </ul>
                  </li>
                );
              }

              if (error.code === 'invalid-operations') {
                return (
                  <li key={index}>
                    {error.message}. Allowed operations:{' '}
                    {error.details?.scheduledOperations?.join(', ')}
                  </li>
                );
              }

              return <li key={index}>{error.message}</li>;
            })}
          </ul>
        </ErrorComponent>
      )}
      <Formik
        initialValues={initialFormValues}
        onSubmit={(values, actions) => {
          if (!values.operations?.length) {
            actions.setErrors({ operations: 'At least 1 operation must be selected.' });
            return;
          }
          if (values.includeEmail && !values.emailTemplate) {
            actions.setErrors({
              emailTemplate: 'Email template is required if "Include Email" is set to "Yes"',
            });
            return;
          }

          // build modal text
          const clusterIdentifier = values.meshId
            ? `mesh #${values.meshId}`
            : `cluster #${values.rcpId}`;
          const actionItems = [
            values.includeEmail
              ? 'Send email to users subscribed to operational emails for this subscription'
              : '',
            values.setMaintenanceMode
              ? `Set maintenance mode to "${
                  values.maintenanceType === 'start' ? 'on' : 'off'
                }" for ${clusterIdentifier}`
              : '',
            values.includeSystemLog
              ? `Write system log entry for ${
                  values.maintenanceType === 'start' ? 'start of' : 'end of'
                } maintenance`
              : '',
          ].filter((value) => value);

          const actionsText = actionItems.length ? (
            <>
              <br />
              The following actions will be taken:{' '}
              <ul>
                {actionItems.map((item) => (
                  <li key={item}>- {item}</li>
                ))}
              </ul>
            </>
          ) : (
            'No customer-facing actions will be triggered.'
          );

          const identifier = values.rcpId ? { rcpId: values.rcpId } : { meshId: values.meshId };

          setRequestPayload({
            ...identifier,
            maintenanceType: values.maintenanceType || '',
            operations: values.operations || [],
            isUrgent: values.isUrgent || false,
            includeSystemLog: values.includeSystemLog || false,
            includeEmail: values.includeEmail || false,
            setMaintenanceMode: values.setMaintenanceMode || false,
            internalNotes: values.internalNotes || null,
            ...(values.includeEmail && values.emailTemplate
              ? { emailTemplate: values.emailTemplate }
              : {}),
            bypassMaintenanceWindow: values.bypassMaintenanceWindow,
            bypassExclusions: values.bypassExclusions,
          });
          setClusterIdentifier(clusterIdentifier);
          setModalText(actionsText);
          setIsModalOpen(true);
        }}
      >
        <MaintenanceActivityForm isSaving={isLoading} />
      </Formik>

      <Modal.Compose open={isModalOpen} onOpenChange={() => setIsModalOpen(!isModalOpen)}>
        <Modal.Content.Compose
          style={{
            width: '50rem',
          }}
        >
          <Modal.Content.Header title="Confirmation" />
          <Modal.Content.Body.Compose>
            <Modal.Content.Body.Text>
              Are you sure you wish to trigger a maintenance activity for {clusterIdentifier}?
            </Modal.Content.Body.Text>
            {modalText}
          </Modal.Content.Body.Compose>
          <Modal.Content.Footer
            onPrimaryButtonClick={handleSubmitModal}
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
          />
        </Modal.Content.Compose>
      </Modal.Compose>
    </>
  );
};

export default MaintenanceActivityFormWrapper;
