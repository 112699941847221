import styled from 'styled-components';

export const TableContainer = styled.div`
  width: 100%;
  overflow: auto;
  border: 1px solid #f4f6f7;
  border-radius: 0.8rem;
`;

export const StyledTable = styled.table`
  min-width: 100%;
  color: #4d6992;

  tr {
    border-bottom: 1px solid #f4f6f7;
    background: #ffffff;
  }

  th {
    vertical-align: text-top;
    font-weight: 600;
    color: rgb(1, 17, 42);
  }

  th,
  td {
    padding: 1rem;
  }

  th,
  tr {
    word-break: normal;
  }

  tr:hover {
    background-color: #eeeeee;
  }

  th.sortable {
    cursor: pointer;
  }

  th.sortable > div {
    display: flex;
    flex-direction: row;
  }

  th > div > div > svg {
    visibility: hidden;
  }

  th > div > div:nth-child(2) {
    width: 16px;
  }

  th.sortable:hover > div > div > svg,
  th.sorted > div > div > svg {
    visibility: visible;
  }

  th.sortable > div > div:first-child {
    flex-grow: 1;
  }

  input[type='checkbox']:disabled {
    cursor: not-allowed;
  }
`;

export const NoBreakCell = styled.td`
  white-space: nowrap;
  min-width: 5rem;
`;

export const RightAlignCell = styled.td`
  white-space: nowrap;
  min-width: 5rem;
  text-align: right;
`;

export const StyledTextWithTooltip = styled.span`
  text-decoration: underline;
  text-decoration-style: dotted;
  cursor: help;
`;

export const TableControls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

export const StyledPre = styled.pre`
  font-family: inherit;
  margin: 0;
`;

export const RedCell = styled.div`
  color: red;
`;

export const OrangeCell = styled.div`
  color: #db9b46;
`;
