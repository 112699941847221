import { useQuery } from 'react-query';
import { api, buildUrl } from '../../../api/config';
import { MaintenanceWindow } from '../MaintenanceWindowPage.api';

export type ScheduledMaintenanceRecord = {
  id: number;
  subscription_id: number;
  rcp_id: number;
  mesh_id: number;
  account_id: number;
  account_name: string;
  maintenance_window_start: string;
  maintenance_window_end: string;
  sendgrid_friendly_name: string;
  sendgrid_id: string;
  operations: string[];
  active_maintenance?: {
    maintenance_timestamp: string;
    operations: string[];
  };
  maintenance_windows: MaintenanceWindow[];
  available_scheduled_maintenance_hours: number;
  events_stream_queue_timestamp: string | null;
  expected_email_delivery_date: string | null;
};

export type AdvanceNotificationsResult = {
  totalRecords: number;
  records: ScheduledMaintenanceRecord[];
};

export const getScheduledMaintenance = async (
  page: number,
  size: number
): Promise<AdvanceNotificationsResult> => {
  const { data } = await api.get<AdvanceNotificationsResult>(
    `${buildUrl('maintenanceWindow')}/scheduled-maintenance`,
    {
      params: {
        page,
        size,
      },
    }
  );
  return data;
};
export const useScheduledMaintenance = (page: number, size: number) => {
  return useQuery({
    queryKey: ['scheduled-maintenances', page, size],
    queryFn: () => {
      return getScheduledMaintenance(page, size);
    },
    refetchOnWindowFocus: false,
  });
};
