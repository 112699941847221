import { Tabs, Button } from '@redislabsdev/redis-ui-components';
import { useCallback, useEffect, useState } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { Loader, showToast } from '@redislabsdev/redislabs-ui-components';
import omit from 'lodash/omit';
import ProtectedComponent, {
  useUserHasPermission,
} from '../../components/ProtectedComponent/ProtectedComponent';
import {
  MAINTENANCE_ADVANCE_NOTIFICATION_READ,
  MAINTENANCE_ADVANCE_NOTIFICATION_WRITE,
  MAINTENANCE_EMAIL_TEMPLATE_READ,
  MAINTENANCE_EMAIL_TEMPLATE_WRITE,
  MAINTENANCE_EVENT_WRITE,
  MAINTENANCE_WINDOW_READ,
} from '../../constants/permissionsConstants';
import * as CS from '../../styles/common.style';
import { defaultApi, buildUrl } from '../../api/config';
import EmailTemplates from './EmailTemplates';
import Exclusions from './Exclusions';
import MaintenanceActivityFormWrapper from './MaintenanceActivityFormWrapper';
import {
  MaintenanceWindowClustersFilters,
  MaintenanceWindowExclusionsFilters,
  Sort,
} from './MaintenanceWindow.types';
import MaintenanceWindowTable from './MaintenanceWindowTable';
import MaintenanceWindowTableFilters from './MaintenanceWindowTableFilters';
import { StyledTab } from './Tabs.style';
import { FilterOptionsResponse, blankFilters, getFilterOptions } from './MaintenanceWindowPage.api';
import { FilterSectionWrapper } from './TableFilters.style';
import { ErrorComponent, PaddedTabContainer } from './MaintenanceWindowPage.style';
import ScheduledMaintenance from './ScheduledMaintenance';
import ScheduledMaintenanceFormWrapper from './ScheduledMaintenanceFormWrapper';
import NotificationsFormWrapper from './NotificationsFormWrapper';
import MaintenanceHistory from './MaintenanceHistory';
import { TableControls } from './Table.style';

const basePath = '/maintenanceWindow';

const buildTab = (key: string, title: string, selectedTab: string) => (
  <StyledTab selected={selectedTab === key} key={key} to={`${basePath}/${key}`}>
    {title}
  </StyledTab>
);

const MaintenanceWindowPage = () => {
  const { tab } = useParams<{ tab: string }>();

  const [clustersFilters, setClustersFilters] = useState<MaintenanceWindowClustersFilters>({});
  const [exclusionsFilters, setExclusionsFilters] = useState<MaintenanceWindowExclusionsFilters>({
    exclusionExpiredStatus: 'active',
  });
  const [isLoadingFilters, setIsLoadingFilters] = useState(true);
  const [filtersError, setFiltersError] = useState(false);
  const [filterOptions, setFilterOptions] = useState<FilterOptionsResponse | undefined>();

  const [clustersPage, setClustersPage] = useState(0);
  const [exclusionsPage, setExclusionsPage] = useState(0);

  const [clustersSort, setClustersSort] = useState<Sort | null>(null);
  const [exclusionsSort, setExclusionsSort] = useState<Sort | null>(null);

  const [isExporting, setIsExporting] = useState(false);

  const hasMaintenanceEventWritePermission = useUserHasPermission(MAINTENANCE_EVENT_WRITE);
  const hasEmailTemplatePermissions = useUserHasPermission(
    [MAINTENANCE_EMAIL_TEMPLATE_READ, MAINTENANCE_EMAIL_TEMPLATE_WRITE],
    true
  );
  const hasAdvanceNotificationReadPermission = useUserHasPermission(
    MAINTENANCE_ADVANCE_NOTIFICATION_READ
  );
  const hasAdvanceNotificationWritePermission = useUserHasPermission(
    MAINTENANCE_ADVANCE_NOTIFICATION_WRITE
  );

  const loadFilterOptions = useCallback(() => {
    setIsLoadingFilters(true);
    setFiltersError(false);
    getFilterOptions()
      .then((result) => {
        setFilterOptions(result.data);
      })
      .catch(() => {
        setFiltersError(true);
      })
      .finally(() => {
        setIsLoadingFilters(false);
      });
  }, []);

  useEffect(() => {
    loadFilterOptions();
  }, []);

  const tabs = [
    buildTab('clusters', 'Clusters', tab),
    buildTab('exclusions', 'Exclusions', tab),
    ...(hasMaintenanceEventWritePermission
      ? [buildTab('manualActivity', 'Manual Activity', tab)]
      : []),
    ...(hasEmailTemplatePermissions ? [buildTab('emailTemplates', 'Email Templates', tab)] : []),
    ...(hasAdvanceNotificationReadPermission
      ? [buildTab('advanceNotifications', 'Scheduled Maintenance', tab)]
      : []),
    ...(hasAdvanceNotificationWritePermission
      ? [buildTab('notifications', 'General Notifications', tab)]
      : []),
    buildTab('maintenanceActivities', 'Maintenance Activities', tab),
  ];

  const onDownloadClick = async () => {
    setIsExporting(true);
    const url = `${buildUrl('maintenanceWindow')}/clusters/export`;

    defaultApi
      .get(url, {
        responseType: 'blob',
        params: {
          ...omit(clustersFilters, blankFilters(clustersFilters)),
          ...clustersSort,
        },
      })
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file and click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'clusters.csv');
        document.body.appendChild(link);
        link.click();

        // cleanup
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch(() => {
        showToast('Error exporting data. Please try again at a later time.', 'error');
      })
      .finally(() => {
        setIsExporting(false);
      });
  };

  return (
    <>
      <CS.PageTitleAndActions>
        <CS.JustifyToLeft>
          <CS.PageHeaderTitle>Maintenance Window</CS.PageHeaderTitle>
        </CS.JustifyToLeft>
      </CS.PageTitleAndActions>

      <Tabs>
        <Tabs.Labels.Compose>{tabs.map((tab) => tab)}</Tabs.Labels.Compose>
      </Tabs>

      <Switch>
        <Route
          path={`${basePath}/clusters`}
          render={() => (
            <PaddedTabContainer>
              <FilterSectionWrapper data-testid="mw-filter-section">
                {isLoadingFilters ? <Loader /> : null}
                {filtersError ? (
                  <ErrorComponent>
                    Unable to load filter options.{' '}
                    <a
                      href=""
                      onClick={(e) => {
                        e.preventDefault();
                        loadFilterOptions();
                      }}
                    >
                      Click here to retry
                    </a>
                    .
                  </ErrorComponent>
                ) : null}
                {!filtersError && !isLoadingFilters && filterOptions ? (
                  <MaintenanceWindowTableFilters
                    initialFilterValues={clustersFilters}
                    handleSubmit={(values: MaintenanceWindowClustersFilters) => {
                      setClustersPage(0);
                      setClustersFilters({ ...values });
                      setClustersSort(null);
                    }}
                    filterOptions={filterOptions}
                  />
                ) : null}
              </FilterSectionWrapper>
              <TableControls>
                <ProtectedComponent requiredPermissions={MAINTENANCE_WINDOW_READ}>
                  <Button onClick={onDownloadClick} style={{ marginRight: '1rem ' }}>
                    Export Results
                  </Button>
                </ProtectedComponent>
              </TableControls>
              {isExporting ? (
                <>
                  <p style={{ textAlign: 'center' }}>Generating export. Please wait...</p>
                  <Loader />
                </>
              ) : (
                <MaintenanceWindowTable
                  filters={clustersFilters}
                  page={clustersPage}
                  setPage={setClustersPage}
                  sort={clustersSort}
                  setSort={setClustersSort}
                />
              )}
            </PaddedTabContainer>
          )}
        />
        <Route
          path={`${basePath}/exclusions`}
          render={() => (
            <Exclusions
              filters={exclusionsFilters}
              handleFiltersSubmit={(values: MaintenanceWindowExclusionsFilters) => {
                setExclusionsPage(0);
                setExclusionsFilters({ ...values });
                setClustersSort(null);
              }}
              page={exclusionsPage}
              setPage={setExclusionsPage}
              sort={exclusionsSort}
              setSort={setExclusionsSort}
            />
          )}
        />
        <Route
          path={`${basePath}/manualActivity`}
          render={() => (
            <ProtectedComponent requiredPermissions={MAINTENANCE_EVENT_WRITE}>
              <MaintenanceActivityFormWrapper />
            </ProtectedComponent>
          )}
        />
        <Route
          path={`${basePath}/emailTemplates`}
          render={() => (
            <ProtectedComponent
              requiredPermissions={[
                MAINTENANCE_EMAIL_TEMPLATE_READ,
                MAINTENANCE_EMAIL_TEMPLATE_WRITE,
              ]}
              partialPermissions
            >
              <EmailTemplates />
            </ProtectedComponent>
          )}
        />
        <Route
          path={`${basePath}/advanceNotifications/create/:subscriptionType/:subscriptionId`}
          render={() => (
            <ProtectedComponent requiredPermissions={MAINTENANCE_ADVANCE_NOTIFICATION_WRITE}>
              <ScheduledMaintenanceFormWrapper />
            </ProtectedComponent>
          )}
          exact
        />
        <Route
          path={`${basePath}/advanceNotifications`}
          render={() => (
            <ProtectedComponent requiredPermissions={MAINTENANCE_ADVANCE_NOTIFICATION_READ}>
              <ScheduledMaintenance />
            </ProtectedComponent>
          )}
        />
        <Route
          path={`${basePath}/notifications`}
          render={() => (
            <ProtectedComponent requiredPermissions={MAINTENANCE_ADVANCE_NOTIFICATION_WRITE}>
              <NotificationsFormWrapper />
            </ProtectedComponent>
          )}
        />
        <Route path={`${basePath}/maintenanceActivities`} render={() => <MaintenanceHistory />} />
      </Switch>
    </>
  );
};

export default MaintenanceWindowPage;
