import React, { useMemo, useState } from 'react';
import omit from 'lodash/omit';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { Input, MultiSelect, OptionType } from '@redislabsdev/redis-ui-components';
import { Button, SingleSelectorDropDown } from '@redislabsdev/redislabs-ui-components';
import { ResetIcon } from '@redislabsdev/redis-ui-icons';
import ProtectedComponent from '../../components/ProtectedComponent/ProtectedComponent';
import { MAINTENANCE_ADVANCE_NOTIFICATION_READ } from '../../constants/permissionsConstants';
import { FilterInputsGroup, SubmitGroup, FilterInputsWrapper } from './TableFilters.style';
import { MaintenanceWindowClustersFilters } from './MaintenanceWindow.types';
import {
  blankOption,
  booleanOptions,
  operationsOptions,
  optInStatusOptions,
} from './Select.options';
import getDefaultDropDownOption from './getDefaultDropDownOption';
import { MaintenanceWindowDateRangePicker } from './MaintenanceWindowDateRangePicker';
import { FilterOptionsResponse } from './MaintenanceWindowPage.api';

const numberRegex = /^[0-9\b]+$/;

type TableFiltersProps = {
  initialFilterValues: MaintenanceWindowClustersFilters;
  handleSubmit: (values: MaintenanceWindowClustersFilters) => void;
  filterOptions: FilterOptionsResponse;
};

const MaintenanceWindowTableFilters: React.FC<TableFiltersProps> = ({
  initialFilterValues,
  handleSubmit,
  filterOptions,
}) => {
  const [filters, setFilters] = useState<MaintenanceWindowClustersFilters>(
    initialFilterValues || {}
  );

  const regionOptions: OptionType[] = useMemo(
    () =>
      sortBy(
        filterOptions.regions.map((region) => {
          const cloud = filterOptions.cloudProviders.find(({ id }) => id === region.cloudId);

          return {
            value: `${region.id}`,
            label: `[${cloud?.name}] ${region.name}`,
            checked: false,
            key: `${region.id}`,
          };
        }),
        ['label']
      ),
    [filterOptions]
  );

  const providerOptions: OptionType[] = useMemo(
    () =>
      sortBy(
        filterOptions.cloudProviders.map((cloud) => {
          return {
            value: `${cloud.id}`,
            label: cloud.name,
            checked: false,
            key: `${cloud.id}`,
          };
        }),
        ['label']
      ),
    [filterOptions]
  );

  // select
  const [defaultSelectValues, setDefaultSelectValues] = useState({
    excluded: getDefaultDropDownOption(initialFilterValues.excluded, booleanOptions),
    manualMaintenanceWindow: getDefaultDropDownOption(
      initialFilterValues.manualMaintenanceWindow,
      booleanOptions
    ),
    offSM: getDefaultDropDownOption(initialFilterValues.offSM, booleanOptions),
    optInStatus: getDefaultDropDownOption(initialFilterValues.optInStatus, optInStatusOptions),
    advanceNotificationEligible: getDefaultDropDownOption(
      initialFilterValues.advanceNotificationEligible,
      booleanOptions
    ),
    requireAdvanceNotification: getDefaultDropDownOption(
      initialFilterValues.requireAdvanceNotification,
      booleanOptions
    ),
  });

  // multi-select
  const defaultRegionOptions = initialFilterValues.regions?.length
    ? regionOptions.map((option) => ({
        ...option,
        checked: initialFilterValues.regions?.includes(option.value) || false,
      }))
    : regionOptions;
  const defaultProviderOptions = initialFilterValues.providers?.length
    ? providerOptions.map((option) => ({
        ...option,
        checked: initialFilterValues.providers?.includes(option.value) || false,
      }))
    : providerOptions;
  const defaultMaintenanceActivityOptions = initialFilterValues.maintenanceActivity?.length
    ? operationsOptions.map((option) => ({
        ...option,
        checked: initialFilterValues.maintenanceActivity?.includes(option.value) || false,
      }))
    : operationsOptions;

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, regex?: RegExp) => {
    if (!regex) {
      handleFilterChange(event.target.name, event.target.value);
      return;
    }

    if (event.target.value === '' || regex.test(event.target.value)) {
      handleFilterChange(event.target.name, event.target.value);
    }
  };

  const [isRegionDropdownOpen, setIsRegionDropdownOpen] = useState(false);
  const [isProviderDropdownOpen, setIsProviderDropdownOpen] = useState(false);
  const [isMaintenancActivityDropdownOpen, setIsMaintenancActivityDropdownOpen] = useState(false);
  const [regionOptionsState, setRegionOptionsState] = useState<OptionType[]>(defaultRegionOptions);
  const [providerOptionsState, setProviderOptionsState] = useState<OptionType[]>(
    defaultProviderOptions
  );
  const [maintenanceActivityOptionsState, setMaintenanceActivityOptionsState] = useState<
    OptionType[]
  >(defaultMaintenanceActivityOptions);

  const handleFilterChange = (fieldName, value) => {
    if (!value || (isArray(value) && isEmpty(value))) {
      setFilters((prevState) => omit(prevState, fieldName));
    } else {
      setFilters((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    }
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(filters);
      }}
    >
      <FilterInputsWrapper>
        <FilterInputsGroup>
          <label htmlFor="rcpID">
            RCP ID
            <Input
              type="text"
              name="rcpID"
              id="rcpID"
              value={filters.rcpID || ''}
              onChange={(e) => onChangeHandler(e, numberRegex)}
            />
          </label>

          <label htmlFor="clusterVersion">
            Cluster Version
            <Input
              type="text"
              name="clusterVersion"
              id="clusterVersion"
              value={filters.clusterVersion || ''}
              onChange={onChangeHandler}
            />
          </label>
        </FilterInputsGroup>

        <FilterInputsGroup>
          <label htmlFor="meshID">
            Mesh ID
            <Input
              type="text"
              name="meshID"
              id="meshID"
              value={filters.meshID || ''}
              onChange={(e) => onChangeHandler(e, numberRegex)}
            />
          </label>
          <label htmlFor="Provider">
            Provider
            <MultiSelect
              onValueChange={(option, checked) => {
                const newOptions = providerOptionsState.map((providerOption) => {
                  if (providerOption.value === option) {
                    return {
                      ...providerOption,
                      checked,
                    } as OptionType;
                  }
                  return providerOption;
                });
                setProviderOptionsState(newOptions);

                const selectedValues = newOptions
                  .filter(({ checked }) => checked)
                  .map(({ value }) => value);
                handleFilterChange('providers', selectedValues);
              }}
              options={providerOptionsState}
              placeholder="Select"
              open={isProviderDropdownOpen}
              onOpenChange={() => {
                setIsProviderDropdownOpen(!isProviderDropdownOpen);
              }}
            />
          </label>
        </FilterInputsGroup>

        <FilterInputsGroup>
          <label htmlFor="subscriptionID">
            Subscription ID
            <Input
              type="text"
              name="subscriptionID"
              id="subscriptionID"
              value={filters.subscriptionID || ''}
              onChange={(e) => onChangeHandler(e, numberRegex)}
            />
          </label>

          <label htmlFor="regions">
            Region
            <MultiSelect
              onValueChange={(option, checked) => {
                const newOptions = regionOptionsState.map((regionOption) => {
                  if (regionOption.value === option) {
                    return {
                      ...regionOption,
                      checked,
                    } as OptionType;
                  }
                  return regionOption;
                });
                setRegionOptionsState(newOptions);

                const selectedValues = newOptions
                  .filter(({ checked }) => checked)
                  .map(({ value }) => value);
                handleFilterChange('regions', selectedValues);
              }}
              options={regionOptionsState}
              placeholder="Select"
              open={isRegionDropdownOpen}
              onOpenChange={() => {
                setIsRegionDropdownOpen(!isRegionDropdownOpen);
              }}
            />
          </label>
        </FilterInputsGroup>

        <FilterInputsGroup>
          <label htmlFor="accountID">
            Account ID
            <Input
              type="text"
              name="accountID"
              id="accountID"
              value={filters.accountID || ''}
              onChange={(e) => onChangeHandler(e, numberRegex)}
            />
          </label>
          <label htmlFor="accountName">
            Account Name
            <Input
              type="text"
              name="accountName"
              id="accountName"
              value={filters.accountName || ''}
              onChange={onChangeHandler}
            />
          </label>
        </FilterInputsGroup>

        <FilterInputsGroup>
          <label htmlFor="excluded">
            Excluded
            <SingleSelectorDropDown
              data-role="dropdown-button"
              optionList={booleanOptions}
              reset={!filters.excluded}
              defaultValue={defaultSelectValues.excluded}
              borderRadius
              borderColor="gray1"
              headerBorder
              getSelectedOption={(item) => {
                handleFilterChange('excluded', `${item?.id}`);
              }}
            />
          </label>

          <label htmlFor="offSM">
            Off SM
            <SingleSelectorDropDown
              data-role="dropdown-button"
              optionList={booleanOptions}
              reset={!filters.offSM}
              defaultValue={defaultSelectValues.offSM}
              borderRadius
              borderColor="gray1"
              headerBorder
              getSelectedOption={(item) => {
                handleFilterChange('offSM', `${item?.id}`);
              }}
            />
          </label>
        </FilterInputsGroup>

        <FilterInputsGroup className="date-picker">
          <MaintenanceWindowDateRangePicker
            htmlFor="startDate"
            filters={filters}
            title="Maintenance Window Date"
            handleFilterChange={handleFilterChange}
            filterStartString="start"
            filterEndString="end"
            testIdSuffix=""
          />
          <MaintenanceWindowDateRangePicker
            htmlFor="lastMaintenanceTime"
            title="Last Maintenance Time"
            filters={filters}
            handleFilterChange={handleFilterChange}
            filterStartString="lastMaintenanceTimeStart"
            filterEndString="lastMaintenanceTimeEnd"
            testIdSuffix="-last-maintenance-time"
          />
        </FilterInputsGroup>

        <FilterInputsGroup>
          <label htmlFor="manualMaintenanceWindow">
            Manual Window
            <SingleSelectorDropDown
              data-role="dropdown-button"
              optionList={booleanOptions}
              reset={!filters.manualMaintenanceWindow}
              defaultValue={defaultSelectValues.manualMaintenanceWindow}
              borderRadius
              borderColor="gray1"
              headerBorder
              getSelectedOption={(item) => {
                handleFilterChange('manualMaintenanceWindow', `${item?.id}`);
              }}
            />
          </label>

          <label htmlFor="optInStatus">
            Opt In
            <SingleSelectorDropDown
              data-role="dropdown-button"
              optionList={optInStatusOptions}
              reset={!filters.optInStatus}
              defaultValue={defaultSelectValues.optInStatus}
              borderRadius
              borderColor="gray1"
              headerBorder
              getSelectedOption={(item) => {
                handleFilterChange('optInStatus', `${item?.id}`);
              }}
            />
          </label>
        </FilterInputsGroup>
        <FilterInputsGroup>
          <label htmlFor="nodeCount">
            Node Count
            <Input
              type="text"
              name="nodeCount"
              id="nodeCount"
              value={filters.nodeCount || ''}
              onChange={(e) => onChangeHandler(e, numberRegex)}
            />
          </label>
        </FilterInputsGroup>
        <ProtectedComponent requiredPermissions={MAINTENANCE_ADVANCE_NOTIFICATION_READ}>
          <>
            <FilterInputsGroup>
              <label htmlFor="requireAdvanceNotification">
                Advance Notification Eligible
                <SingleSelectorDropDown
                  data-role="dropdown-button"
                  optionList={booleanOptions}
                  reset={!filters.advanceNotificationEligible}
                  defaultValue={defaultSelectValues.advanceNotificationEligible}
                  borderRadius
                  borderColor="gray1"
                  headerBorder
                  getSelectedOption={(item) => {
                    handleFilterChange('advanceNotificationEligible', `${item?.id}`);
                  }}
                />
              </label>
            </FilterInputsGroup>
            <FilterInputsGroup>
              <label htmlFor="requireAdvanceNotification">
                Require Advance Notification
                <SingleSelectorDropDown
                  data-role="dropdown-button"
                  optionList={booleanOptions}
                  reset={!filters.requireAdvanceNotification}
                  defaultValue={defaultSelectValues.requireAdvanceNotification}
                  borderRadius
                  borderColor="gray1"
                  headerBorder
                  getSelectedOption={(item) => {
                    handleFilterChange('requireAdvanceNotification', `${item?.id}`);
                  }}
                />
              </label>
            </FilterInputsGroup>
            <FilterInputsGroup>
              <MaintenanceWindowDateRangePicker
                htmlFor="scheduledMaintenanceDate"
                filters={filters}
                title="Scheduled Maintenance Date"
                handleFilterChange={handleFilterChange}
                filterStartString="scheduledMaintenanceDateStart"
                filterEndString="scheduledMaintenanceDateEnd"
                testIdSuffix="-scheduled-maintenance-date"
              />
            </FilterInputsGroup>
            <FilterInputsGroup>
              <label htmlFor="maintenanceActivity">
                Last Maintenance Activity
                <MultiSelect
                  onValueChange={(option, checked) => {
                    const newOptions = maintenanceActivityOptionsState.map(
                      (maintenanceActivityOption) => {
                        if (maintenanceActivityOption.value === option) {
                          return {
                            ...maintenanceActivityOption,
                            checked,
                          } as OptionType;
                        }
                        return maintenanceActivityOption;
                      }
                    );
                    setMaintenanceActivityOptionsState(newOptions);

                    const selectedValues = newOptions
                      .filter(({ checked }) => checked)
                      .map(({ value }) => value);
                    handleFilterChange('maintenanceActivity', selectedValues);
                  }}
                  options={maintenanceActivityOptionsState}
                  placeholder="Select"
                  open={isMaintenancActivityDropdownOpen}
                  onOpenChange={() => {
                    setIsMaintenancActivityDropdownOpen(!isMaintenancActivityDropdownOpen);
                  }}
                />
              </label>
            </FilterInputsGroup>
          </>
        </ProtectedComponent>
      </FilterInputsWrapper>
      <SubmitGroup>
        <Button
          variant="link"
          onClick={() => {
            setFilters({});
            setDefaultSelectValues({
              excluded: blankOption,
              manualMaintenanceWindow: blankOption,
              offSM: blankOption,
              optInStatus: blankOption,
              advanceNotificationEligible: blankOption,
              requireAdvanceNotification: blankOption,
            });
            setRegionOptionsState(
              regionOptions.map((option) => ({
                ...option,
                checked: false,
              }))
            );
            setProviderOptionsState(
              providerOptions.map((option) => ({
                ...option,
                checked: false,
              }))
            );
            setMaintenanceActivityOptionsState(
              operationsOptions.map((option) => ({
                ...option,
                checked: false,
              }))
            );
          }}
        >
          <ResetIcon size="M" />
          <span>Clear All</span>
        </Button>
        <Button variant="secondary" type="submit">
          Apply Filters
        </Button>
      </SubmitGroup>
    </form>
  );
};

export default MaintenanceWindowTableFilters;
