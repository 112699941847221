import { RadioGroup } from '@redislabsdev/redis-ui-components';
import styled from 'styled-components';

export const ButtonRow = styled.div`
  width: 100%;
  margin: 1rem 0;
  text-align: right;
`;

export const FlexRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: row;
`;
