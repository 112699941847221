import ProtectedComponent from '../ProtectedComponent/ProtectedComponent';

type Props = {
  children: React.ReactElement;
  requiredPermissions?: string | string[];
};

const OptionallyProtectedComponent = ({ requiredPermissions, children }: Props) => {
  if (!requiredPermissions) {
    return children;
  }

  return (
    <ProtectedComponent requiredPermissions={requiredPermissions}>{children}</ProtectedComponent>
  );
};

export default OptionallyProtectedComponent;
