import { Input, RadioGroup, TextArea } from '@redislabsdev/redis-ui-components';
import { Button, Loader, SingleSelectorDropDown } from '@redislabsdev/redislabs-ui-components';
import { Field, useField, useFormikContext } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { showToast } from '../../components/Toast/Toast';
import { FlexRadioGroup } from './Forms.styles';
import { MaintenanceEventFormState } from './MaintenanceWindow.types';
import { EmailTemplatesResponse, getEmailTemplatesRequest } from './MaintenanceWindowPage.api';
import { StyledFormColumn, StyledFormRow } from './Form.style';
import { Operations } from './Operations';

const StyledError = styled.div`
  color: red;
`;

const blankOption = { id: '', title: '' };

const rcpOrMeshIdRequired = 'RCP Id or Mesh Id is required.';
const rcpAndMeshNotAllowed = 'RCP Id and Mesh Id cannot both be defined.';

const numberRegex = /^[0-9\b]+$/;

const MaintenanceActivityForm: React.FC<{ isSaving: boolean }> = ({ isSaving }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [shouldResetEmailTemplate, setShouldResetEmailTemplate] = useState<boolean>(false);
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplatesResponse[]>([]);
  const [shouldSubmitForm, setShouldSubmitForm] = useState(false);

  const {
    handleSubmit,
    values: formState,
    handleChange,
    handleBlur,
    errors,
    touched,
  } = useFormikContext<MaintenanceEventFormState>();

  const [, , maintenanceTypeHelpers] = useField({ name: 'maintenanceType' });
  const [, , isUrgentHelpers] = useField({ name: 'isUrgent' });
  const [, , emailTemplateHelpers] = useField({ name: 'emailTemplate' });
  const [, , includeEmailHelpers] = useField({ name: 'includeEmail' });
  const [, , bypassMaintenanceWindowHelpers] = useField({ name: 'bypassMaintenanceWindow' });
  const [, , bypassExclusionsHelpers] = useField({ name: 'bypassExclusions' });
  const [, , includeSystemLogHelpers] = useField({ name: 'includeSystemLog' });
  const [, , setMaintenanceModeHelpers] = useField({ name: 'setMaintenanceMode' });

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, regex?: RegExp) => {
    if (!regex) {
      handleChange(event);
      return;
    }

    if (event.target.value === '' || regex.test(event.target.value)) {
      handleChange(event);
    }
  };

  const validateRcpId = (value) => {
    if (!value && !formState.meshId) {
      return rcpOrMeshIdRequired;
    }
    if (value && formState.meshId) {
      return rcpAndMeshNotAllowed;
    }

    return null;
  };
  const validateMeshId = (value) => {
    if (!value && !formState.rcpId) {
      return rcpOrMeshIdRequired;
    }
    if (value && formState.rcpId) {
      return rcpAndMeshNotAllowed;
    }

    return null;
  };

  useEffect(() => {
    getEmailTemplatesRequest()
      .then((result) => {
        setEmailTemplates(result.data);
      })
      .catch(() => {
        showToast('Error fetching data');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const selectableTemplates = useMemo(
    () =>
      emailTemplates
        .filter(
          (template) =>
            template.is_urgent === formState.isUrgent &&
            template.maintenance_type === formState.maintenanceType
        )
        .map((template) => ({
          id: template.friendly_name,
          title: template.friendly_name,
        })),
    [emailTemplates, formState.isUrgent, formState.maintenanceType]
  );

  if (isLoading) {
    return (
      <div data-testid="mw-fetching-indicator">
        <Loader />
      </div>
    );
  }

  const handleSubmitClick = () => setShouldSubmitForm(true);

  const innerHandleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    if (!shouldSubmitForm) {
      e?.preventDefault();
      return;
    }

    handleSubmit(e);
    setShouldSubmitForm(false);
  };

  return (
    <form onSubmit={innerHandleSubmit}>
      <StyledFormRow>
        <StyledFormColumn>
          <label htmlFor="maintenanceType">
            Activity Type
            <FlexRadioGroup
              initialValue="start"
              onChange={(value: string) => {
                setShouldResetEmailTemplate(true);
                maintenanceTypeHelpers.setValue(value);
                maintenanceTypeHelpers.setTouched(true, false);
              }}
            >
              <RadioGroup.Item.Compose value="start">
                <RadioGroup.Item.Indicator />
                <RadioGroup.Item.Label>Start</RadioGroup.Item.Label>
              </RadioGroup.Item.Compose>
              <RadioGroup.Item.Compose value="end">
                <RadioGroup.Item.Indicator />
                <RadioGroup.Item.Label>End</RadioGroup.Item.Label>
              </RadioGroup.Item.Compose>
            </FlexRadioGroup>
          </label>

          <label htmlFor="isUrgent">
            Urgency
            <FlexRadioGroup
              initialValue="false"
              onChange={(value: string) => {
                const booleanValue = value === 'true';
                setShouldResetEmailTemplate(true);
                isUrgentHelpers.setValue(booleanValue);
                isUrgentHelpers.setTouched(true, false);
              }}
            >
              <RadioGroup.Item.Compose value="false">
                <RadioGroup.Item.Indicator />
                <RadioGroup.Item.Label>Non-Urgent</RadioGroup.Item.Label>
              </RadioGroup.Item.Compose>
              <RadioGroup.Item.Compose value="true">
                <RadioGroup.Item.Indicator />
                <RadioGroup.Item.Label>Urgent</RadioGroup.Item.Label>
              </RadioGroup.Item.Compose>
            </FlexRadioGroup>
          </label>
          <label htmlFor="rcpId">
            RCP Id
            <Field
              name="rcpId"
              validate={validateRcpId}
              render={() => (
                <>
                  <Input
                    type="text"
                    name="rcpId"
                    id="rcpId"
                    onChange={(e) => onChangeHandler(e, numberRegex)}
                    onBlur={handleBlur}
                    value={formState.rcpId}
                  />
                  {touched.rcpId && errors.rcpId && <StyledError>{errors.rcpId}</StyledError>}
                </>
              )}
            />
          </label>
          <label htmlFor="meshId">
            Mesh Id
            <Field
              name="meshId"
              validate={validateMeshId}
              render={() => (
                <>
                  <Input
                    type="text"
                    name="meshId"
                    id="meshId"
                    onChange={(e) => onChangeHandler(e, numberRegex)}
                    onBlur={handleBlur}
                    value={formState.meshId}
                  />
                  {touched.meshId && errors.meshId && <StyledError>{errors.meshId}</StyledError>}
                </>
              )}
            />
          </label>

          <Operations />

          <label htmlFor="bypassMaintenanceWindow">
            Bypass Maintenance Window?
            <FlexRadioGroup
              initialValue="false"
              onChange={(value: string) => {
                const booleanValue = value === 'true';
                bypassMaintenanceWindowHelpers.setValue(booleanValue);
              }}
            >
              <RadioGroup.Item.Compose value="false">
                <RadioGroup.Item.Indicator />
                <RadioGroup.Item.Label>No</RadioGroup.Item.Label>
              </RadioGroup.Item.Compose>
              <RadioGroup.Item.Compose value="true">
                <RadioGroup.Item.Indicator />
                <RadioGroup.Item.Label>Yes</RadioGroup.Item.Label>
              </RadioGroup.Item.Compose>
            </FlexRadioGroup>
          </label>

          <label htmlFor="bypassExclusions">
            Bypass Exclusions?
            <FlexRadioGroup
              initialValue="false"
              onChange={(value: string) => {
                const booleanValue = value === 'true';
                bypassExclusionsHelpers.setValue(booleanValue);
              }}
            >
              <RadioGroup.Item.Compose value="false">
                <RadioGroup.Item.Indicator />
                <RadioGroup.Item.Label>No</RadioGroup.Item.Label>
              </RadioGroup.Item.Compose>
              <RadioGroup.Item.Compose value="true">
                <RadioGroup.Item.Indicator />
                <RadioGroup.Item.Label>Yes</RadioGroup.Item.Label>
              </RadioGroup.Item.Compose>
            </FlexRadioGroup>
          </label>
        </StyledFormColumn>
        <StyledFormColumn>
          <label htmlFor="includeEmail">
            Include Email?
            <FlexRadioGroup
              initialValue="true"
              onChange={(value: string) => {
                const booleanValue = value === 'true';
                includeEmailHelpers.setValue(booleanValue);
              }}
            >
              <RadioGroup.Item.Compose value="false">
                <RadioGroup.Item.Indicator />
                <RadioGroup.Item.Label>No</RadioGroup.Item.Label>
              </RadioGroup.Item.Compose>
              <RadioGroup.Item.Compose value="true">
                <RadioGroup.Item.Indicator />
                <RadioGroup.Item.Label>Yes</RadioGroup.Item.Label>
              </RadioGroup.Item.Compose>
            </FlexRadioGroup>
          </label>

          <label htmlFor="emailTemplate">
            Email Template
            <SingleSelectorDropDown
              disabled={!formState.includeEmail}
              data-role="dropdown-button"
              optionList={selectableTemplates}
              reset={!formState.emailTemplate || shouldResetEmailTemplate}
              borderRadius
              defaultValue={blankOption}
              borderColor="gray1"
              headerBorder
              getSelectedOption={(item) => {
                emailTemplateHelpers.setValue(`${item?.id}`);
                emailTemplateHelpers.setTouched(true, false);
                setShouldResetEmailTemplate(false);
              }}
            />
            {errors.emailTemplate && <StyledError>{errors.emailTemplate}</StyledError>}
          </label>

          <label htmlFor="includeSystemLog">
            Include System Log?
            <FlexRadioGroup
              initialValue="true"
              onChange={(value: string) => {
                const booleanValue = value === 'true';
                includeSystemLogHelpers.setValue(booleanValue);
                includeSystemLogHelpers.setTouched(true, false);
              }}
            >
              <RadioGroup.Item.Compose value="false">
                <RadioGroup.Item.Indicator />
                <RadioGroup.Item.Label>No</RadioGroup.Item.Label>
              </RadioGroup.Item.Compose>
              <RadioGroup.Item.Compose value="true">
                <RadioGroup.Item.Indicator />
                <RadioGroup.Item.Label>Yes</RadioGroup.Item.Label>
              </RadioGroup.Item.Compose>
            </FlexRadioGroup>
          </label>

          <label htmlFor="setMaintenanceMode">
            Update Maintenance Status?
            <FlexRadioGroup
              initialValue="true"
              onChange={(value: string) => {
                const booleanValue = value === 'true';
                setMaintenanceModeHelpers.setValue(booleanValue);
                setMaintenanceModeHelpers.setTouched(true, false);
              }}
            >
              <RadioGroup.Item.Compose value="false">
                <RadioGroup.Item.Indicator />
                <RadioGroup.Item.Label>No</RadioGroup.Item.Label>
              </RadioGroup.Item.Compose>
              <RadioGroup.Item.Compose value="true">
                <RadioGroup.Item.Indicator />
                <RadioGroup.Item.Label>Yes</RadioGroup.Item.Label>
              </RadioGroup.Item.Compose>
            </FlexRadioGroup>
          </label>

          <label htmlFor="internalNotes">
            Internal Notes
            <Field
              name="internalNotes"
              render={() => (
                <>
                  <TextArea
                    name="internalNotes"
                    id="internalNotes"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={formState.internalNotes}
                  />
                </>
              )}
            />
          </label>
        </StyledFormColumn>
      </StyledFormRow>
      <Button variant="primary" type="submit" disabled={isSaving} onClick={handleSubmitClick}>
        Submit
      </Button>
    </form>
  );
};

export default MaintenanceActivityForm;
