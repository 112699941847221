import { OptionType } from '@redislabsdev/redis-ui-components';

export const blankOption = { id: 'blank', title: '' };

export const timeOptions = [{ id: '12:00 AM', title: '12:00 AM' }];

for (let i = 1; i < 12; i++) {
  timeOptions.push({ id: `${i}:00 AM`, title: `${i}:00 AM` });
}
timeOptions.push({ id: '12:00 PM', title: '12:00 PM' });
for (let i = 1; i < 12; i++) {
  timeOptions.push({ id: `${i}:00 PM`, title: `${i}:00 PM` });
}

export const booleanOptions = [
  blankOption,
  { id: 'true', title: 'True' },
  { id: 'false', title: 'False' },
];

export const exclusionExpiredStatusOptions = [
  blankOption,
  { id: 'active', title: 'Active' },
  { id: 'pendingExpiration', title: 'Pending Expiration (Ops)' },
  { id: 'expired', title: 'Expired' },
];

export const optInStatusOptions = [
  blankOption,
  { id: 'pending', title: 'Pending' },
  { id: 'complete', title: 'Complete' },
];

export const initiatorOptions = [
  blankOption,
  { id: 'ops', title: 'Ops' },
  { id: 'customer', title: 'Customer' },
];

export const operationsOptions: OptionType[] = [
  { value: 'optimization', label: 'Optimization', checked: false, key: 'optimization' },
  { value: 'upgrade', label: 'Upgrade', checked: false, key: 'upgrade' },
  { value: 'other', label: 'Other', checked: false, key: 'other' },
];

export const excludedOperationsOptions: OptionType[] = [
  { value: 'optimization', label: 'Optimization', checked: false, key: 'optimization' },
  { value: 'upgrade', label: 'Upgrade', checked: false, key: 'upgrade' },
];

export const maintenanceActivityStatusOptions: OptionType[] = [
  { value: 'inProgress', label: 'In Progress', checked: false, key: 'inProgress' },
  { value: 'finished', label: 'Finished', checked: false, key: 'finished' },
];
