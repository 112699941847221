import { useField } from 'formik';
import { MultiSelect, OptionType } from '@redislabsdev/redis-ui-components';
import React, { useState } from 'react';
import { operationsOptions } from './Select.options';
import { StyledError } from './Form.style';

const required = (value: string[]) => {
  if (!value.length) {
    return 'Field must be filled out.';
  }

  return undefined;
};

const operationTypeValidator = (value: string[]) => {
  if (value.includes('other') && value.length > 1) {
    return 'Other cannot be combined with other operation types.';
  }

  return undefined;
};

const validateOperationType = (value) => {
  return required(value) || operationTypeValidator(value);
};

export const Operations = () => {
  const [operationsOptionsState, setOperationsOptionsState] = useState<OptionType[]>(
    operationsOptions
  );
  const [isOperationsDropdownOpen, setIsOperationsDropdownOpen] = useState(false);

  const [, meta, operationsHelpers] = useField({
    name: 'operations',
    validate: validateOperationType,
  });

  return (
    <label htmlFor="operations">
      Operations
      <MultiSelect
        onValueChange={(option, checked) => {
          const newOptions = operationsOptionsState.map((operationsOption) => {
            if (operationsOption.value === option) {
              return {
                ...operationsOption,
                checked,
              } as OptionType;
            }
            return operationsOption;
          });
          setOperationsOptionsState(newOptions);

          const selectedValues = newOptions
            .filter(({ checked }) => checked)
            .map(({ value }) => value);

          operationsHelpers.setTouched(true, false);
          operationsHelpers.setValue(selectedValues);
        }}
        options={operationsOptionsState}
        placeholder="Select"
        open={isOperationsDropdownOpen}
        onOpenChange={() => {
          operationsHelpers.setTouched(true, false);
          setIsOperationsDropdownOpen(!isOperationsDropdownOpen);
        }}
      />
      {meta.touched && meta.error && <StyledError>{meta.error}</StyledError>}
    </label>
  );
};
