import { theme } from '@redislabsdev/redis-ui-styles';
import styled, { css } from 'styled-components';
import { MaintenanceHistoryRecord } from './hooks/useMaintenanceHistory';

export const Tr = styled.tr`
  ${(row: MaintenanceHistoryRecord) => {
    if (row.exceedingWindow) {
      return css`
        color: ${theme.semantic.color.text.neutral100} !important;
        background-color: ${theme.semantic.color.background.danger500} !important;
        :hover {
          background-color: ${theme.semantic.color.background.danger300} !important;
        }
      `;
    }
    if (!row.maintenanceEndTime) {
      return css`
        color: ${theme.semantic.color.text.neutral100} !important;
        background-color: ${theme.semantic.color.background.primary600} !important;
        :hover {
          background-color: ${theme.semantic.color.background.primary500} !important;
        }
      `;
    }
  }}
`;
