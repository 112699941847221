import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { parseISO } from 'date-fns';
import { Loader, Tooltip } from '@redislabsdev/redislabs-ui-components';
import { TablePagination } from '@redislabsdev/redislabs-ui-components/ui/components/Table';
import { IconButton, Modal } from '@redislabsdev/redis-ui-components';
import { ContractsIcon, DeleteIcon, SettingsIcon } from '@redislabsdev/redis-ui-icons';
import ProtectedComponent from '../../components/ProtectedComponent/ProtectedComponent';
import { MAINTENANCE_ADVANCE_NOTIFICATION_WRITE } from '../../constants/permissionsConstants';
import { ErrorComponent, PaddedTabContainer } from './MaintenanceWindowPage.style';
import { NoBreakCell, RightAlignCell } from './Table.style';
import formatInTimeZone from './formatInTimeZone';
import {
  ScheduledMaintenanceRecord,
  useScheduledMaintenance,
} from './hooks/useScheduledMaintenance';
import { useDeleteScheduledMaintenance } from './hooks/useDeleteScheduledMaintenance';
import { daysLookup } from './daysLookup';
import MaintenanceWindowsTooltip from './MaintenanceWindowsTooltip';
import { formatScheduledMaintenanceWindow } from './format-scheduled-maintenance-window';
import Table, { TableProps } from './Table';

const dateFormat = 'd-MMM-y HH:mm:ss';

const createColumns = (
  history: ReturnType<typeof useHistory>,
  onDeleteClick: (row: ScheduledMaintenanceRecord) => void
): TableProps<ScheduledMaintenanceRecord>['columns'] => [
  {
    header: 'Subscription ID',
    render: 'subscription_id',
    cellComponent: NoBreakCell,
  },
  {
    header: 'RCP ID',
    render: 'rcp_id',
    cellComponent: NoBreakCell,
  },
  {
    header: 'Mesh ID',
    render: 'mesh_id',
    cellComponent: NoBreakCell,
  },
  {
    header: 'Account ID',
    render: 'account_id',
    cellComponent: NoBreakCell,
  },
  {
    header: 'Account Name',
    render: 'account_name',
  },
  {
    header: 'Scheduled Maintenance Window',
    render: (row: ScheduledMaintenanceRecord) => {
      return (
        <>
          {formatScheduledMaintenanceWindow(
            row.maintenance_window_start,
            row.maintenance_window_end,
            row.available_scheduled_maintenance_hours
          )}
        </>
      );
    },
  },
  {
    header: 'Operations',
    render: (row: ScheduledMaintenanceRecord) => {
      return row.operations.join(', ');
    },
  },
  {
    header: 'Maintenance Windows',
    render: ({ maintenance_windows: windows }: ScheduledMaintenanceRecord) => {
      if (!windows.length) {
        return null;
      }
      const additionalWindows = windows.length > 1 ? `+${windows.length - 1}` : '';
      const cell = `${daysLookup[windows[0].days[0]]}… ${additionalWindows}`;
      return (
        <Tooltip
          tooltipContent={<MaintenanceWindowsTooltip windows={windows} />}
          textColor="#01112a"
        >
          {cell}
        </Tooltip>
      );
    },
  },
  {
    header: 'Active Maintenance Status',
    render: (row: ScheduledMaintenanceRecord) => {
      if (!row.active_maintenance) {
        return null;
      }

      const parsedDate = parseISO(row.active_maintenance.maintenance_timestamp);
      return (
        <div>
          Started {formatInTimeZone(parsedDate, dateFormat, 'UTC')}. <br />
          Operations: {row.active_maintenance.operations.join(', ')}
        </div>
      );
    },
  },
  {
    header: 'Notification Status',
    render: (row: ScheduledMaintenanceRecord) => {
      if (row.events_stream_queue_timestamp) {
        return <>Sent</>;
      }

      return (
        <Tooltip
          tooltipContent="Email notification will be sent to the customer 7 days prior to the end of the scheduled maintenance."
          textColor="#01112a"
          placement="left"
        >
          Pending
        </Tooltip>
      );
    },
  },
  {
    header: 'Notification Date',
    render: (row: ScheduledMaintenanceRecord) => {
      const timestamp = row.events_stream_queue_timestamp || row.expected_email_delivery_date;
      const parsedDate = timestamp ? parseISO(timestamp) : null;
      return <>{parsedDate ? formatInTimeZone(parsedDate, dateFormat, 'UTC') : ''}</>;
    },
  },
  {
    header: '',
    cellComponent: RightAlignCell,
    render: (row: ScheduledMaintenanceRecord) => (
      <ProtectedComponent requiredPermissions={MAINTENANCE_ADVANCE_NOTIFICATION_WRITE}>
        <>
          <Tooltip
            tooltipContent="Link to the manual activity form for this cluster"
            textColor="#01112a"
            placement="left"
          >
            <IconButton
              size="XL"
              icon={SettingsIcon}
              onClick={() => {
                const paramName = row.mesh_id ? 'mesh_id' : 'rcp_id';
                const paramValue = row[paramName];
                history.push(`/maintenanceWindow/manualActivity?${paramName}=${paramValue}`);
              }}
            />
          </Tooltip>
          <Tooltip
            tooltipContent="Link to the notification form for this cluster"
            textColor="#01112a"
            placement="left"
          >
            <IconButton
              size="XL"
              style={{ marginLeft: '1rem' }}
              icon={ContractsIcon}
              onClick={() => {
                const paramName = row.mesh_id ? 'mesh_id' : 'rcp_id';
                const paramValue = row[paramName];
                history.push(`/maintenanceWindow/notifications?${paramName}=${paramValue}`);
              }}
            />
          </Tooltip>
          <IconButton
            size="XL"
            style={{ marginLeft: '1rem' }}
            icon={DeleteIcon}
            onClick={() => onDeleteClick(row)}
          />
        </>
      </ProtectedComponent>
    ),
  },
];

const ScheduledMaintenance = () => {
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [sendCancellationEmailChecked, setSendCancellationEmailChecked] = useState(true);
  const { data, isFetching, error, refetch } = useScheduledMaintenance(page, size);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteValues, setDeleteValues] = useState<ScheduledMaintenanceRecord>();

  const totalRecords = data?.totalRecords || 0;
  const pageCount = Math.ceil(totalRecords / size);
  const history = useHistory();

  const deleteAdvanceNotificationMutation = useDeleteScheduledMaintenance({
    onSuccess: () => {
      // decrement page if we have deleted the last item on page
      const newPageNumber = page > 0 && data?.records?.length === 1 ? page - 1 : page;
      setPage(newPageNumber);
      refetch();
    },
  });

  const fetchError = error ? 'Unable to fetch list data.' : null;
  const deleteError = deleteAdvanceNotificationMutation.error
    ? 'An error occurred while deleting scheduled maintenance.'
    : null;

  const isLoading = isFetching || deleteAdvanceNotificationMutation.isLoading;

  const onDeleteClick = (row: ScheduledMaintenanceRecord) => {
    setDeleteValues(row);
    setIsDeleteModalOpen(true);
  };

  const columns = createColumns(history, onDeleteClick);

  const handleDeleteSubmit = async () => {
    if (!deleteValues?.id) {
      return;
    }

    deleteAdvanceNotificationMutation.mutate({
      advancedNotificationId: deleteValues?.id,
      sendCancellationEmail: deleteValues.events_stream_queue_timestamp
        ? sendCancellationEmailChecked
        : false,
    });
  };

  return (
    <PaddedTabContainer>
      <Modal.Compose
        open={isDeleteModalOpen}
        onOpenChange={(value) => {
          setIsDeleteModalOpen(value);

          // reset default checkbox state
          setSendCancellationEmailChecked(true);
        }}
      >
        <Modal.Content.Compose
          style={{
            width: '50rem',
          }}
        >
          <Modal.Content.Header title="Cancel?" />
          <Modal.Content.Body.Compose>
            <p>Are you sure you wish to cancel this scheduled maintenance?</p>
            {deleteValues?.events_stream_queue_timestamp && (
              <>
                <br />
                <p>
                  <input
                    id="sendCancellationEmail"
                    type="checkbox"
                    checked={sendCancellationEmailChecked}
                    onChange={() => {
                      setSendCancellationEmailChecked(!sendCancellationEmailChecked);
                    }}
                  />{' '}
                  <label htmlFor="sendCancellationEmail">Send cancellation email</label>
                </p>
              </>
            )}
          </Modal.Content.Body.Compose>
          <Modal.Content.Footer
            onPrimaryButtonClick={handleDeleteSubmit}
            primaryButtonText="Confirm"
            secondaryButtonText="Cancel"
          />
        </Modal.Content.Compose>
      </Modal.Compose>
      {isLoading && (
        <div data-testid="mw-fetching-indicator">
          <Loader />
        </div>
      )}
      {fetchError && <ErrorComponent>{fetchError}</ErrorComponent>}
      {deleteError && <ErrorComponent>{deleteError}</ErrorComponent>}
      {!isLoading && data && (
        <div>
          <Table data={data.records} columns={columns} rowKey="id" />
          <TablePagination
            isPaginatedControlled={true}
            rows={data.records || []}
            canNextPage={page + 1 < pageCount}
            canPreviousPage={page > 0}
            pageIndex={page}
            pageCount={pageCount}
            pageSize={size}
            gotoPage={(pageIndex: number) => {
              setPage(pageIndex);
            }}
            nextPage={() => {
              setPage(page + 1);
            }}
            previousPage={() => {
              setPage(page - 1);
            }}
            setPageSize={(newSize: number) => {
              setSize(newSize);
            }}
          />
        </div>
      )}
    </PaddedTabContainer>
  );
};

export default ScheduledMaintenance;
