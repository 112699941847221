import styled from 'styled-components/macro';

export const NewPocPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 51rem;

  & > div {
    padding: 1rem 0;
  }
`;

export const NewPocPopupRow = styled.div`
  display: flex;

  & > label [for='accountName'] {
    pointer-events: none;
  }

  & > label {
    width: 100%;
    margin: 0 1.5rem;

    & input[name='accountName'] {
      pointer-events: none;
      border: 0;
      font-weight: bold;
    }
  }
`;

export const DatePickerAligner = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.75);
  top: 0;
  left: 0;
`;
