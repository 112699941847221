import styled from 'styled-components';
import { cardWithShadow } from '../../styles/commonAccountDetails.style';

export const StyledFormColumn = styled.div`
  ${cardWithShadow};

  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1em;
  margin: 1em 0;
`;

export const StyledFormRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;

  label {
    flex-grow: 1;
  }
`;

export const StyledError = styled.div`
  color: red;
`;

export const DateButtonRow = styled.div`
  width: 100%;
  text-align: right;
  margin: 1.5rem 0 0;
`;
