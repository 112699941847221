import * as R from 'ramda';
import { api, buildUrl, fileApi } from '../../../api/config';
import { showToast } from '../../../components/Toast/Toast';
import { displayErrors } from '../../rootPage/RootPage.utils';

export async function checkForProSubscriptions(accounts, dispatch) {
  const accountsArray: string[] = Array.from(accounts.keys());
  const promises = accountsArray.map((acc) =>
    api.get(`${buildUrl('accounts')}/${acc}/has-pro-subscriptions`)
  );

  await Promise.all(promises).then((responses) => {
    const containsSubs = responses.map(({ data }, index) => {
      return data.hasProSubscriptions && accountsArray[index];
    });

    dispatch({ type: 'activeProSubsAccountIds', payload: R.without([false], containsSubs) });
  });
}

export async function deleteContract(contractId) {
  api
    .delete(`${buildUrl('contracts')}/${contractId}`)
    .then(({ status }) => {
      if (status === 200) {
        showToast(`Contract ${contractId} was successfully deleted`, 'success');
      }
    })
    .catch((err) => {
      displayErrors(err, 'Failed trying to delete a contract');
    });
}

export function denyBasedOnStatus(status) {
  const deniedStatusList = [
    'deleted',
    'expired',
    'marketplace-deleted',
    'marketplace-expired',
  ];

  return deniedStatusList.includes(status);
}

export function disableBasedOnStatus(status) {
  const disabledStatusList = [
    'expanded'
  ];

  return disabledStatusList.includes(status);
}

const getUsageReportRequest = (contractId) =>
  api.get(`${buildUrl('contracts')}/monthly-reports/${contractId}`);

export async function getUsageReport(contractId, onSuccessCallback?) {
  return getUsageReportRequest(contractId)
    .then(({ status, data }) => {
      if (status === 200) {
        onSuccessCallback(data);
      }
    })
    .catch((err) => {
      displayErrors(err, `Failed to get Usage Reports for Contract ID #${contractId}`);
    });
}

const downloadConcreteReportRequest = (contractId, date) =>
  fileApi.get(`${buildUrl('contracts')}/monthly-reports/${contractId}/${date}`);

export async function downloadConcreteReport(contractId, date) {
  return downloadConcreteReportRequest(contractId, date)
    .then(({ status, data, headers }) => {
      if (status === 200) {
        const [, filename] = headers['content-disposition'].split('filename=');
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch((err) => {
      displayErrors(err, `Failed to get Usage Reports for Contract ID #${contractId}`);
    });
}

// TODO: combine download functions since they do the same thing (especially the 'then' call);
const downloadAllReportRequest = (contractId) =>
  fileApi.get(`${buildUrl('contracts')}/monthly-reports/${contractId}/all`);

export async function downloadAllReports(contractId) {
  return downloadAllReportRequest(contractId)
    .then(({ status, data, headers }) => {
      if (status === 200) {
        const [, filename] = headers['content-disposition'].split('filename=');
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch((err) => {
      displayErrors(err, `Failed to download all Usage Reports for Contract ID #${contractId}`);
    });
}
