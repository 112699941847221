import styled, { css } from 'styled-components/macro';
import { DatePicker, Tooltip } from '@redislabsdev/redislabs-ui-components';
import { theme } from '@redislabsdev/redis-ui-styles';
import { Popover } from '@redislabsdev/redis-ui-components';

const SINGLE_ROW_FILTER_BAR_HEIGHT = '9.6rem';
const TWO_ROWS_FILTER_BAR_HEIGHT = '16.6rem';

// TODO: move constants to constants folder
// TODO: Split this file by sections (Filter, table... etc)

const viewValuesCssRules = css`
  color: #01112a;
  padding: 0.9rem 0;
`;

const couponDisabledStatuses = ['coupon-depleted', 'coupon-expired', 'coupon-disabled'];

export const PageTitleAndActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8rem;
`;

export const JustifyToLeft = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  & button {
    background-color: #d2defc;
    color: #253dac;
    margin-right: 3rem;
  }
`;

export const JustifyToRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & button {
    margin-left: 0.6rem;
  }
`;

export const PageHeaderTitle = styled.h1`
  color: ${theme.semantic.color.text.neutral800};
  font-size: 2.8rem;
  font-weight: bold;
`;

export const FilterSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;

  margin-bottom: 2rem;
`;

export const FilterSection = styled.div<{ singleRow: boolean }>`
  display: flex;
  flex: 1;
  flex-direction: row;
  min-height: ${({ singleRow = true }) =>
    singleRow ? SINGLE_ROW_FILTER_BAR_HEIGHT : TWO_ROWS_FILTER_BAR_HEIGHT};
  background: #ffffff 0 0 no-repeat padding-box;
  box-shadow: 0 0 16px #0000000f;
  border-radius: 0.8rem;
`;

export const FilterRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  & label {
    display: flex;
    flex-direction: column;
    margin: 1.3rem 0 1.3rem 3rem;
    font-size: 1.5rem;

    & span:last-child {
      display: flex;
    }
  }

  & label[for='status'] {
    & > div > div:first-child {
      border: solid 0.1rem #c1cbd9;
      height: 3.6rem;
    }
  }

  & label[for='startDate'] > div,
  & label[for='expiryDate'] > div,
  & label[for='endDate'] > div {
    width: 23rem;
  }
`;

export const SpanWithDate = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  font-size: 1.5rem;
  height: 3.6rem;
  width: 100%;
  border: ${({ disabled }) => (disabled ? 'none' : 'solid 0.1rem #C1CBD9')};
  border-radius: 4px;
  transition: border-color 0.3s ease-in;

  &:hover,
  &:focus {
    border-color: ${theme.semantic.color.border.informative400};
  }

  > span {
    // width: 20rem;
    align-self: center;
    padding-left: 0.8rem;
  }

  & button {
    border: 0;
    padding: 0;
    margin: 0;
    height: 3.6rem;
    left: auto;
    right: 0;
    position: absolute;

    & > svg {
      width: 4rem;
    }
  }

  & > div:last-child {
    top: -12rem;
    left: 25rem;
    z-index: 200;
    width: ${({ useAsRange }) => (useAsRange ? '52.8rem' : '28.2rem')};
  }
`;

export const SpanWithDateButtonsWrapper = styled.span`
  button {
    left: auto;
    right: 5px;
    position: absolute;
    width: 2.8rem;
    height: 2.8rem;
    top: 3px;
    border-radius: 4px;
    min-width: auto;
`;

export const Textarea = styled.textarea`
  border-color: #c1cbd9;
  transition: border-color 0.3s ease-in;

  &:hover,
  &:focus {
    border-color: ${theme.semantic.color.border.informative400};
  }
`;

export const ViewValues = styled.span<{ displayBlock: boolean }>`
  ${viewValuesCssRules};
  font-size: ${({ useAsTitle }) => (useAsTitle ? '1.8rem' : '1.5rem')};
  ${({ displayBlock }) => displayBlock && 'display: block'}
`;

export const TooltipViewValues = styled.span`
  ${viewValuesCssRules};
  text-overflow: ellipsis;
  overflow: hidden;
  display: block !important;
`;

export const TooltipStyleOverride = styled(Tooltip)`
  min-width: 0;
`;

export const TooltipOverflowHidden = styled.div`
  min-width: 0;
  display: flex;
`;

export const TableActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 2.75rem 0 1.55rem 0;
`;

export const TableWrapper = styled.div`
  & div[role='row'] {
    font-size: 1.55rem;
  }
`;

export const StatusLoading = styled.div`
  padding-top: 2.5rem;
  width: 16rem;
`;

export const ClearAndApplyOneRowWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 3rem;

  & button:first-child {
    padding-bottom: 1rem;
  }
`;

export const DatePickerWrapper = styled(DatePicker)`
  top: 0;
  left: 0;
  position: relative;
  width: 100%;
`;

export const StatusWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const StatusValue = styled.div<{ customStatusWidth: string }>`
  width: ${({ customStatusWidth }) => customStatusWidth || '8rem'};
`;

export const Status = styled.div<{ useFullWidth: boolean }>`
  display: flex;
  align-items: center;
  ${({ useFullWidth }) => useFullWidth && 'width: 100%'};

  & > span:first-child {
    padding: 0.5rem;
    margin-right: 0.8rem;
    border-radius: 50px;
    background-color: ${({ status }) => {
      if (status === 'deleted' || status === 'marketplace-deleted') return '#F74B57';
      if (status === 'inactive') return '#b0b0b0';
      if (status === 'expired' || status === 'marketplace-expired') return '#F9AD48';
      if (status === 'expanded') return '#695e42';
      if (status === 'inactive-pending') return '#c3b491';

      if (status === 'coupon-active') return '#5BC69B';
      if (couponDisabledStatuses.includes(status)) return '#FFAF2B';

      return '#4FDAE0';
    }};
  }

  & span:last-child {
    padding-top: 0.5rem;
  }
`;

export const TextWithTooltip = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  align-self: center;
  white-space: nowrap;
  width: 100%;
`;

export const TooltipTextWithTooltip = styled.p`
  padding: 9px 14px;
  text-align: center;
  align-self: center;
`;

export const TooltipWrapper = styled.div`
  overflow: hidden;

  & > span {
    display: flex;
  }
`;

export const DualDatePickerPopover = styled(Popover.Content.Compose)`
  min-width: 59.2rem;
  overflow: visible;
`;

export const DualDatePickerPopoverBody = styled(Popover.Content.Body.Compose)`
  display: flex;
  width: 100%;
`;
