import { Formik } from 'formik';
import { useState } from 'react';
import { AxiosError } from 'axios';
import moment from 'moment';
import { Button, DatePicker, Modal, MultiSelect } from '@redislabsdev/redis-ui-components';
import { TextArea, showToast } from '@redislabsdev/redislabs-ui-components';
import { excludedOperationsOptions } from './Select.options';
import { editExclusionsRequest } from './MaintenanceWindowPage.api';
import { StyledError, StyledFormColumn, StyledFormRow } from './ExclusionForm.style';

type FormValues = {
  updateOperations: boolean;
  updateEndDate: boolean;
  updateReason: boolean;
  operations: ('optimization' | 'upgrade')[];
  endDate: Date;
  reason: string;
};

const validate = (values: FormValues) => {
  const errors: { [T in keyof FormValues]?: string } = {};

  if (values.updateOperations && values.operations.length === 0) {
    errors.operations = 'Must provide at least one operation';
  }

  if (!values.updateOperations && !values.updateEndDate && !values.updateReason) {
    errors.updateOperations = 'At least one field must be updated';
  }

  if (values.updateEndDate && !values.endDate) {
    errors.endDate = 'Must select an end date';
  }

  return errors;
};

type BulkExclusionFormProps = {
  exclusionIds: number[];
  onClose(): void;
};

const BulkExclusionForm = (props: BulkExclusionFormProps) => {
  const [isOperationsDropdownOpen, setIsOperationsDropdownOpen] = useState(false);
  const [operationsOptionsState, setOperationsOptionsState] = useState(excludedOperationsOptions);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  return (
    <Formik
      initialValues={{
        updateOperations: false,
        updateEndDate: false,
        updateReason: false,
        operations: [] as ('optimization' | 'upgrade')[],
        endDate: new Date(),
        reason: '',
      }}
      validate={validate}
      onSubmit={() => {
        setIsConfirmationModalOpen(true);
      }}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit} style={{ width: '60rem' }}>
          <StyledFormColumn>
            <h2>Bulk Edit Exclusions</h2>
            <div
              style={{
                padding: '0.8rem 1.2rem',
                color: '#002f75',
                background: 'rgb(245, 248, 255)',
                border: '1px solid rgb(162, 184, 242)',
                borderRadius: '1rem',
              }}
            >
              <p>
                The changes you provide will be applied to{' '}
                <strong>{props.exclusionIds.length}</strong> selected exclusion
                {props.exclusionIds.length > 1 ? 's' : ''}. If editing the end date, make sure that
                the new end date is later than the start date for all affected exclusions.
              </p>
              <p>
                <strong>This action cannot be undone.</strong>
              </p>
            </div>
            <div>Update fields:</div>
            <StyledFormRow>
              <div>
                <input
                  type="checkbox"
                  id="updateOperations"
                  {...formik.getFieldProps('updateOperations')}
                  style={{ marginRight: '0.5rem' }}
                />
                <label htmlFor="updateOperations">Operations</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  id="updateEndDate"
                  {...formik.getFieldProps('updateEndDate')}
                  style={{ marginRight: '0.5rem' }}
                />
                <label htmlFor="updateEndDate">End Date</label>
              </div>
              <div>
                <input
                  type="checkbox"
                  id="updateReason"
                  {...formik.getFieldProps('updateReason')}
                  style={{ marginRight: '0.5rem' }}
                />
                <label htmlFor="updateReason">Reason</label>
              </div>
            </StyledFormRow>
            {formik.errors.updateOperations &&
            (formik.touched.updateOperations ||
              formik.touched.updateEndDate ||
              formik.touched.updateReason) ? (
              <StyledError>{formik.errors.updateOperations}</StyledError>
            ) : null}

            {formik.values.updateOperations && (
              <>
                <label htmlFor="operations">
                  Operations
                  <MultiSelect
                    onValueChange={(option, checked) => {
                      const newOperations = checked
                        ? [...formik.values.operations, option]
                        : formik.values.operations.filter((o) => o !== option);
                      formik.setFieldValue('operations', newOperations);
                      formik.setFieldTouched('operations', true, false);
                      setOperationsOptionsState(
                        excludedOperationsOptions.map((o) => ({
                          ...o,
                          checked: newOperations.includes(o.value),
                        }))
                      );
                    }}
                    options={operationsOptionsState}
                    placeholder="Select"
                    open={isOperationsDropdownOpen}
                    onOpenChange={() => {
                      setIsOperationsDropdownOpen(!isOperationsDropdownOpen);
                    }}
                  />
                </label>
                {formik.errors.operations && formik.touched.operations ? (
                  <StyledError>{formik.errors.operations}</StyledError>
                ) : null}
              </>
            )}

            {formik.values.updateEndDate && (
              <>
                <label htmlFor="endDate">
                  End Date
                  <DatePicker
                    selectedDay={formik.values.endDate}
                    onSingleSelect={(value) => {
                      formik.setFieldValue('endDate', value);
                      formik.setFieldTouched('endDate', true, false);
                    }}
                  />
                </label>
                {formik.errors.endDate && formik.touched.endDate ? (
                  <StyledError>{formik.errors.endDate}</StyledError>
                ) : null}
              </>
            )}

            {formik.values.updateReason && (
              <div>
                <label htmlFor="reason">Reason</label>
                <TextArea
                  name="reason"
                  id="reason"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  maxLength={3000}
                />
              </div>
            )}

            <div>
              <Button
                type="submit"
                disabled={formik.isSubmitting || Object.values(formik.errors).some((e) => e)}
              >
                Submit
              </Button>
              <Button
                type="button"
                variant="secondary-ghost"
                onClick={props.onClose}
                style={{ marginLeft: '1rem' }}
              >
                Cancel
              </Button>
            </div>
          </StyledFormColumn>
          <Modal.Compose open={isConfirmationModalOpen} onOpenChange={setIsConfirmationModalOpen}>
            <Modal.Content.Compose style={{ width: '50rem' }}>
              <Modal.Content.Header title="Update Exclusions?" />
              <Modal.Content.Body.Compose>
                <p>
                  You are about to update {props.exclusionIds.length} exclusion
                  {props.exclusionIds.length > 1 ? 's' : ''}. This action cannot be undone.
                </p>
              </Modal.Content.Body.Compose>
              <Modal.Content.Footer
                onPrimaryButtonClick={async () => {
                  try {
                    await editExclusionsRequest({
                      exclusionIds: props.exclusionIds,
                      payload: {
                        operations: formik.values.updateOperations
                          ? formik.values.operations
                          : undefined,
                        endDate: formik.values.updateEndDate
                          ? moment
                              .utc([
                                formik.values.endDate.getFullYear(),
                                formik.values.endDate.getMonth(),
                                formik.values.endDate.getDate(),
                                23,
                                59,
                                59,
                              ])
                              .format()
                          : undefined,
                        reason: formik.values.updateReason ? formik.values.reason : undefined,
                      },
                    });
                    showToast(
                      `Successfully updated ${props.exclusionIds.length} exclusion${
                        props.exclusionIds.length > 1 ? 's' : ''
                      }.`,
                      'success'
                    );
                    props.onClose();
                  } catch (error) {
                    const axiosError = error as AxiosError;
                    if (
                      axiosError.isAxiosError &&
                      axiosError.response?.data.code === 'exclusion-count-mismatch'
                    ) {
                      showToast(
                        `Unable to update ${props.exclusionIds.length} exclusions. Make sure that the provided end date is later than the start date for all exclusions.`,
                        'error'
                      );
                    } else {
                      showToast(`An unexpected error occurred.`, 'error');
                    }
                  }
                }}
                primaryButtonText="Confirm"
                secondaryButtonText="Cancel"
              />
            </Modal.Content.Compose>
          </Modal.Compose>
        </form>
      )}
    </Formik>
  );
};

export default BulkExclusionForm;
