import React from 'react';
import { Field, useField, useFormikContext } from 'formik';
import { SingleSelectorDropDown } from '@redislabsdev/redislabs-ui-components';
import { Button, Input } from '@redislabsdev/redis-ui-components';
import { StyledError, StyledFormColumn, StyledFormRow, StyledFormWrapper } from './Form.style';

const blankOption = { id: '', title: '' };

export type NotificationsFormState = {
  emailTemplate: string;
  rcpId?: string | null;
  meshId?: string | null;
};

const rcpOrMeshIdRequired = 'RCP Id or Mesh Id is required.';
const rcpAndMeshNotAllowed = 'RCP Id and Mesh Id cannot both be defined.';

const numberRegex = /^[0-9\b]+$/;

type NotificationsFormProps = {
  isSubmitting: boolean;
  selectableTemplates: { id: string; title: string }[];
};

const NotificationsForm = ({ isSubmitting, selectableTemplates }: NotificationsFormProps) => {
  const [, , emailTemplateHelpers] = useField({ name: 'emailTemplate' });

  const {
    values: formState,
    handleChange,
    handleBlur,
    errors,
    touched,
    handleSubmit,
  } = useFormikContext<NotificationsFormState>();

  const validateRcpId = (value) => {
    if (!value && !formState.meshId) {
      return rcpOrMeshIdRequired;
    }
    if (value && formState.meshId) {
      return rcpAndMeshNotAllowed;
    }

    return null;
  };
  const validateMeshId = (value) => {
    if (!value && !formState.rcpId) {
      return rcpOrMeshIdRequired;
    }
    if (value && formState.rcpId) {
      return rcpAndMeshNotAllowed;
    }

    return null;
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, regex?: RegExp) => {
    if (!regex) {
      handleChange(event);
      return;
    }

    if (event.target.value === '' || regex.test(event.target.value)) {
      handleChange(event);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <StyledFormWrapper>
        <StyledFormRow>
          <StyledFormColumn>
            <label htmlFor="rcpId">
              RCP Id
              <Field
                name="rcpId"
                validate={validateRcpId}
                render={() => (
                  <>
                    <Input
                      type="text"
                      name="rcpId"
                      id="rcpId"
                      onChange={(e) => onChangeHandler(e, numberRegex)}
                      onBlur={handleBlur}
                      value={formState.rcpId || undefined}
                    />
                    {touched.rcpId && errors.rcpId && <StyledError>{errors.rcpId}</StyledError>}
                  </>
                )}
              />
            </label>
            <label htmlFor="meshId">
              Mesh Id
              <Field
                name="meshId"
                validate={validateMeshId}
                render={() => (
                  <>
                    <Input
                      type="text"
                      name="meshId"
                      id="meshId"
                      onChange={(e) => onChangeHandler(e, numberRegex)}
                      onBlur={handleBlur}
                      value={formState.meshId || undefined}
                    />
                    {touched.meshId && errors.meshId && <StyledError>{errors.meshId}</StyledError>}
                  </>
                )}
              />
            </label>
            <label htmlFor="emailTemplate">
              Email Template
              <SingleSelectorDropDown
                data-role="dropdown-button"
                optionList={selectableTemplates}
                borderRadius
                defaultValue={blankOption}
                borderColor="gray1"
                headerBorder
                getSelectedOption={(item) => {
                  emailTemplateHelpers.setValue(`${item?.id}`);
                  emailTemplateHelpers.setTouched(true, false);
                }}
              />
              {errors.emailTemplate && <StyledError>{errors.emailTemplate}</StyledError>}
            </label>
          </StyledFormColumn>
        </StyledFormRow>
        <Button variant="primary" type="submit" disabled={isSubmitting}>
          Submit Notification
        </Button>
      </StyledFormWrapper>
    </form>
  );
};

export default NotificationsForm;
