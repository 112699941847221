import styled from 'styled-components/macro';
import { cardWithShadow } from '../../styles/commonAccountDetails.style';

export const StyledFormRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;

  & > div {
    width: 50%;
  }
`;

export const StyledFormColumn = styled.div`
  ${cardWithShadow};

  padding: 1em;
  margin: 1em 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const StyledInnerRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;

  & > * {
    flex-grow: 1;
  }
`;

export const StyledInnerRowStatic = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;

  & > * {
    flex-basis: 50%;
  }
`;

export const StyledReadonlyValue = styled.div`
  font-weight: bold;
  display: block;
`;

export const StyledFormWrapper = styled.div`
  margin-bottom: 2rem;
`;

export const StyledError = styled.div`
  color: red;
`;
