import React, { useEffect, useState } from 'react';
import { Button, Modal, TextArea } from '@redislabsdev/redis-ui-components';

export type MaintenanceWindowClusterEditModalProps = {
  cluster?: {
    rcpId: number | null;
    meshId: number | null;
    regionName: string;
    clusterNotes: string | null;
  };
  onSubmit: (updatedClusterNotes: string) => void;
  onClose: () => void;
};

const MaintenanceWindowClusterEditModal: React.FC<MaintenanceWindowClusterEditModalProps> = ({
  cluster,
  onSubmit,
  onClose,
}) => {
  const [clusterNotesFormValue, setClusterNotesFormValue] = useState('');

  useEffect(() => {
    setClusterNotesFormValue(cluster?.clusterNotes || '');
  }, [cluster]);

  return (
    <Modal.Compose
      open={!!cluster}
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <Modal.Content.Compose>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(clusterNotesFormValue);
          }}
        >
          <Modal.Content.Header title="Special Notes" />
          <Modal.Content.Body.Compose>
            <div style={{ marginBottom: '1rem' }}>
              {cluster?.rcpId && <div>RCP ID: {cluster?.rcpId}</div>}
              {cluster?.meshId && (
                <>
                  <div>Mesh ID: {cluster?.meshId}</div>
                  <div>Region: {cluster?.regionName}</div>
                </>
              )}
            </div>
            <TextArea
              id="clusterNotesInput"
              value={clusterNotesFormValue}
              onChange={(event) => {
                setClusterNotesFormValue(event.target.value);
              }}
            />
          </Modal.Content.Body.Compose>
          <Modal.Content.Footer.Compose style={{ justifyContent: 'right' }}>
            <Button
              type="button"
              variant="secondary-ghost"
              size="large"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" size="large" style={{ marginLeft: '.5rem' }}>
              Submit
            </Button>
          </Modal.Content.Footer.Compose>
        </form>
      </Modal.Content.Compose>
    </Modal.Compose>
  );
};

export default MaintenanceWindowClusterEditModal;
