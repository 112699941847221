import { blankOption } from './Select.options';

export const getDefaultDropDownOption = (
  initialFilterValue: string | null | undefined,
  options: {
    id: string;
    title: string;
  }[],
  defaultOption = blankOption
) => {
  if (initialFilterValue) {
    return options.find((option) => option.id === initialFilterValue) || defaultOption;
  }

  return defaultOption;
};

export default getDefaultDropDownOption;
