import { useMutation, UseMutationOptions } from 'react-query';
import { AxiosError } from 'axios';
import { api, buildUrl } from '../../../api/config';
import { MaintenanceWindowErrorResponse } from './types';

export type UseDeleteScheduledMaintenanceRequest = {
  advancedNotificationId: number;
  sendCancellationEmail: boolean;
};

export const deleteScheduledMaintenanceRequest = ({
  advancedNotificationId,
  sendCancellationEmail,
}: UseDeleteScheduledMaintenanceRequest) =>
  api.delete(
    `${buildUrl(
      'maintenanceWindow'
    )}/scheduled-maintenance/${advancedNotificationId}?sendCancellationEmail=${sendCancellationEmail}`
  );

export const useDeleteScheduledMaintenance = (
  options?: UseMutationOptions<
    void,
    AxiosError<MaintenanceWindowErrorResponse>,
    UseDeleteScheduledMaintenanceRequest
  >
) => {
  return useMutation<
    void,
    AxiosError<MaintenanceWindowErrorResponse>,
    UseDeleteScheduledMaintenanceRequest
  >(
    'delete-scheduled-maintenance',
    async (request: UseDeleteScheduledMaintenanceRequest) => {
      await deleteScheduledMaintenanceRequest(request);
    },
    options
  );
};
