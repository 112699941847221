import { MaintenanceWindow } from './MaintenanceWindowPage.api';
import { daysLookup } from './daysLookup';
import { formatTime } from "./format-time";

type MaintenanceWindowsTooltipProps = {
  windows: MaintenanceWindow[];
};

const mod = (x: number, n: number) => ((x % n) + n) % n;

const MaintenanceWindowsTooltip = ({ windows }: MaintenanceWindowsTooltipProps) => {
  const lines = windows.map((win, i) => {
    const days = win.days.map((d) => daysLookup[d]).join(', ');
    const hour = mod(win.from, 12) || 12; // if 0 then return 12 for 12 AM
    const line = (
      <li key={i}>{`${days} from ${formatTime(hour)} ${win.from < 12 ? 'AM' : 'PM'} for ${
        win.duration
      }h (UTC)`}</li>
    );
    return line;
  });
  const tooltip = <ul>{lines}</ul>;
  return tooltip;
};

export default MaintenanceWindowsTooltip;
