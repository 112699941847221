import React, { useEffect, useState } from 'react';
import omit from 'lodash/omit';
import { Button, Loader } from '@redislabsdev/redislabs-ui-components';
import { Formik } from 'formik';
import { IconButton, Modal } from '@redislabsdev/redis-ui-components';
import { DeleteIcon, EditIcon } from '@redislabsdev/redis-ui-icons';
import { showToast } from '../../components/Toast/Toast';
import ProtectedComponent, {
  useUserHasPermission,
} from '../../components/ProtectedComponent/ProtectedComponent';
import { MAINTENANCE_EMAIL_TEMPLATE_WRITE } from '../../constants/permissionsConstants';
import {
  createEmailTemplateRequest,
  deleteEmailTemplateRequest,
  EmailTemplatesResponse,
  getEmailTemplatesRequest,
  updateEmailTemplateRequest,
} from './MaintenanceWindowPage.api';
import EmailTemplateForm from './EmailTemplateForm';
import { ButtonRow } from './Forms.styles';
import { EmailTemplateFormState } from './MaintenanceWindow.types';
import Table, { TableProps } from './Table';

const templateTypes = {
  start: 'Maintenance Start',
  end: 'Maintenance End',
  general: 'General Notification',
  advanceNotification: 'Advance Notification',
};

const getColumns = (
  hasWritePermission: boolean,
  setTemplateToEdit: React.Dispatch<React.SetStateAction<EmailTemplatesResponse | null>>,
  setIsFormVisible: React.Dispatch<React.SetStateAction<boolean>>,
  confirmDelete: (sendgridTemplateId: number) => void
) => {
  const columns: TableProps<EmailTemplatesResponse>['columns'] = [
    {
      header: 'Template Name',
      render: 'friendly_name',
    },
    {
      header: 'Sendgrid Id',
      render: 'sendgrid_id',
    },
    {
      header: 'Template Type',
      render: (row) => {
        return <>{templateTypes[row.maintenance_type]}</>;
      },
    },
    {
      header: 'Urgency',
      render: (row) => {
        if (!['start', 'end'].includes(row.maintenance_type)) {
          return null;
        }
        return <div>{row.is_urgent ? 'Urgent' : 'Non-Urgent'}</div>;
      },
    },
    ...(hasWritePermission
      ? [
          {
            header: ' ',
            render: (row) => {
              return (
                <div style={{ textAlign: 'right', width: '100%' }}>
                  <IconButton
                    size="XL"
                    icon={EditIcon}
                    onClick={() => {
                      setTemplateToEdit(row);
                      setIsFormVisible(true);
                    }}
                  />
                  <IconButton
                    size="XL"
                    style={{ marginLeft: '1rem' }}
                    icon={DeleteIcon}
                    onClick={() => {
                      confirmDelete(row.email_template_id);
                    }}
                  />
                </div>
              );
            },
          },
        ]
      : []),
  ];

  return columns;
};

const EmailTemplates = () => {
  const [loading, setLoading] = useState(true);
  const [isConfirmDeleteModalVisible, setIsConfirmDeleteModalVisible] = useState(false);
  const [templateToDeleteId, setTemplateToDeleteId] = useState<number | undefined>();
  const [templateToEdit, setTemplateToEdit] = useState<EmailTemplatesResponse | null>(null);
  const [results, setResults] = useState<EmailTemplatesResponse[]>([]);
  const [isFormVisible, setIsFormVisible] = useState(false);

  const hasWritePermission = useUserHasPermission(MAINTENANCE_EMAIL_TEMPLATE_WRITE);

  const columns = getColumns(
    hasWritePermission,
    setTemplateToEdit,
    setIsFormVisible,
    (sendgridTemplateId) => {
      setTemplateToDeleteId(sendgridTemplateId);
      setIsConfirmDeleteModalVisible(true);
    }
  );

  const fetchListData = () => {
    setLoading(true);
    getEmailTemplatesRequest()
      .then((result) => {
        setResults(result.data);
      })
      .catch(() => {
        showToast('Error fetching data');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteTemplate = () => {
    if (!templateToDeleteId) {
      return;
    }
    setLoading(true);
    deleteEmailTemplateRequest(templateToDeleteId)
      .then(() => {
        fetchListData();
      })
      .catch(() => {
        showToast('Error deleting template');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchListData();
  }, [isFormVisible]);

  if (isFormVisible) {
    const initialValues: EmailTemplateFormState = templateToEdit || {
      friendly_name: '',
      sendgrid_id: '',
      maintenance_type: 'start',
      is_urgent: false,
    };
    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values: EmailTemplateFormState) => {
          setLoading(true);

          const formattedValues: EmailTemplateFormState = {
            ...omit(values, 'is_urgent'),
            ...(['start', 'end'].includes(values.maintenance_type)
              ? { is_urgent: values.is_urgent }
              : {}),
          };

          const request = values.email_template_id
            ? updateEmailTemplateRequest(formattedValues)
            : createEmailTemplateRequest(formattedValues);

          return request
            .then(() => {
              setIsFormVisible(false);
            })
            .catch(() => {
              showToast('Error saving email template');
            })
            .finally(() => {
              setLoading(false);
            });
        }}
      >
        <EmailTemplateForm setIsFormVisible={setIsFormVisible} />
      </Formik>
    );
  }

  return (
    <>
      {loading && (
        <div data-testid="mw-fetching-indicator">
          <Loader />
        </div>
      )}
      <ProtectedComponent requiredPermissions={MAINTENANCE_EMAIL_TEMPLATE_WRITE}>
        <ButtonRow>
          <Button
            variant="primary"
            onClick={() => {
              setTemplateToEdit(null);
              setIsFormVisible(true);
            }}
            style={{ marginRight: '1rem' }}
          >
            Add Template
          </Button>
        </ButtonRow>
      </ProtectedComponent>
      <Modal.Compose
        open={isConfirmDeleteModalVisible}
        onOpenChange={() => setIsConfirmDeleteModalVisible(!isConfirmDeleteModalVisible)}
      >
        <Modal.Content.Compose
          style={{
            width: '50rem',
          }}
        >
          <Modal.Content.Header title="Confirmation" />
          <Modal.Content.Body.Compose>
            <Modal.Content.Body.Text>
              Are you sure you want to delete this email template?
            </Modal.Content.Body.Text>
          </Modal.Content.Body.Compose>
          <Modal.Content.Footer
            onPrimaryButtonClick={handleDeleteTemplate}
            primaryButtonText="Submit"
            secondaryButtonText="Cancel"
          />
        </Modal.Content.Compose>
      </Modal.Compose>
      <div hidden={loading}>
        <Table data={results} columns={columns} rowKey="email_template_id" />
      </div>
    </>
  );
};

export default EmailTemplates;
