import { api, buildUrl } from '../../api/config';

export type DurationWindow = {
  subscriptionCustomWindowId: number;
  day: number;
  from: number;
  duration: number;
};

export type SubscriptionMetadataResponse = {
  metadata: {
    subscriptionName: string;
    accountId: number;
    accountName: string;
  };
  windows: DurationWindow[];
};

export type ScheduledMaintenanceRequest = {
  subscriptionId: number;
  subscriptionCustomWindowId?: number;
  subscriptionType: string;
  emailTemplate: string;
  maintenanceDate: string;
};

export const getSubscriptionMetadata = (subscriptionId: string, subscriptionType: string) =>
  api.get<SubscriptionMetadataResponse>(
    `${buildUrl('maintenanceWindow')}/subscription-metadata/${subscriptionType}/${subscriptionId}`
  );

export const createScheduledMaintenanceRequest = (values: ScheduledMaintenanceRequest) =>
  api.post(`${buildUrl('maintenanceWindow')}/scheduled-maintenance`, values);
