import { theme } from '@redislabsdev/redis-ui-styles';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const StyledTab = styled(Link)`
  ${({ selected }) =>
    selected &&
    css`
      pointer-events: none;
      color: ${theme.semantic.color.text.primary600};
      border-bottom: 3px solid ${theme.semantic.color.text.primary600};
      margin-bottom: -1px;
    `}
`;
