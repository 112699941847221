import { useState } from 'react';
import { Loader } from '@redislabsdev/redislabs-ui-components';
import MaintenanceHistoryTable from './MaintenanceHistoryTable';
import {
  MaintenanceHistoryFilters,
  MaintenanceHistorySortBy,
  MaintenanceHistorySortDirection,
  useMaintenanceHistory,
} from './hooks/useMaintenanceHistory';
import { ErrorComponent, PaddedTabContainer } from './MaintenanceWindowPage.style';
import MaintenanceHistoryTableFilters from './MaintenanceHistoryTableFilters';

const MaintenanceHistory = () => {
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(100);
  const [sortBy, setSortBy] = useState<MaintenanceHistorySortBy>('status');
  const [sortDirection, setSortDirection] = useState<MaintenanceHistorySortDirection>('asc');
  const [filters, setFilters] = useState<MaintenanceHistoryFilters>({
    operations: [],
    statuses: [],
  });

  const { data, isFetching, error } = useMaintenanceHistory({
    page,
    size,
    sortBy,
    sortDirection,
    ...filters,
  });

  return (
    <PaddedTabContainer>
      <MaintenanceHistoryTableFilters
        onSubmit={(newFilters) => {
          setFilters(newFilters);
          setPage(0);
        }}
      />
      {!data && isFetching && (
        <div data-testid="mw-fetching-indicator">
          <Loader />
        </div>
      )}
      {error && <ErrorComponent>Unable to fetch list data.</ErrorComponent>}
      <div style={{ height: '2.5rem', fontStyle: 'italic' }}>
        {data && isFetching && 'Refreshing...'}
      </div>
      {data && (
        <MaintenanceHistoryTable
          page={page}
          onSetPage={setPage}
          size={size}
          onSetSize={setSize}
          sortBy={sortBy}
          sortDirection={sortDirection}
          onSort={(sortBy, sortDirection) => {
            setSortBy(sortBy);
            setSortDirection(sortDirection);
            setPage(0);
          }}
          rows={data.records}
          totalRecords={data.totalRecords}
        />
      )}
    </PaddedTabContainer>
  );
};

export default MaintenanceHistory;
