import styled, { css } from 'styled-components/macro';
import { Tooltip } from '@redislabsdev/redislabs-ui-components';
import { IconButton } from '@redislabsdev/redis-ui-components';

export const cardWithShadow = css`
  background-color: #ffffff;
  box-shadow: 0 0 16px #0000000f;
  border-radius: 8px;
`;

export const SectionTitle = styled.div`
  border-bottom: 1px solid #eaedf2;
  font-size: 2rem;
  padding: 1rem;
  color: #01112a;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  & Button {
    justify-self: center;
    margin-left: auto;
    border: 0;
    padding: 0;
    border-radius: 50px;
    min-width: 3rem;
  }
`;

export const SectionContent = styled.div`
  display: flex;

  & > div {
    display: flex;
    width: 50%;
    flex-direction: column;
    padding: 0 3.6rem;
  }
`;

export const Separator = styled.span`
  width: 1px;
  margin-top: 4rem;
  background-color: #eaedf2;
`;

export const SeparatorForTitle = styled.span`
  width: 1px;
  margin: 1rem 0;
  background-color: #eaedf2;
`;

export const SpanDisplayFlexInline = styled.span<{
  view?: boolean;
}>`
  display: flex;

  & label {
    display: flex;
    flex-direction: column;
    margin: 2.5rem 0 0 0;
    font-size: 1.5rem;
    width: 100%;
    color: #4d6992;

    ${({ view }) => view && 'white-space: nowrap;'}
    ${({ view }) => view && 'overflow: hidden;'}
    & span:last-child {
      display: flex;
    }
  }

  & label:nth-child(2) {
    margin-left: 3rem;
  }

  & textarea {
    border-color: #c1cbd9;
  }

  & textarea:disabled {
    border: 0;
    resize: none;
    background-color: white;
  }
`;

export const AccName = styled.div`
  font-size: 1.6rem;
  align-self: center;
  width: 22rem;
`;

export const PaymentIcon = styled.span`
  width: 3.6rem;
  margin: 0 0.5rem;
  color: #4d6992;
`;

export const CardNumber = styled.span`
  font-weight: 600;
  margin: 0 1rem;
  color: black;
`;

export const CardExpireDate = styled.span`
  font-size: 1.2rem;
  color: gray;
`;

export const SmAccountsSectionStyle = styled.div`
  margin-top: 2rem;
  ${cardWithShadow};
  max-height: 36rem;
  overflow-y: auto;
`;

export const SMAccountsTitle = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  padding: 1.3rem;
  width: 25%;
  display: flex;
  border-bottom: 1px solid #eaedf2;

  &:last-child {
    width: 50%;
    justify-content: space-between;
  }
`;

export const SMAccountsNewLine = styled.div<{
  view?: boolean;
}>`
  font-size: 1.4rem;
  padding: 1.3rem;
  width: 25%;
  display: flex;

  &:last-child {
    width: 50%;
    justify-content: space-between;
    align-items: center;
  }
`;

export const TooltipContractWrapper = styled.div`
  & > span > div {
    width: 15rem;
  }
`;

export const TooltipAccountsUpdate = styled(Tooltip)`
  color: #01112a;
`;

export const StyledIconButton = styled(IconButton)`
  justify-self: center;
  margin-left: auto;
  border: 0;
  padding: 0;
  border-radius: 50px;
  min-width: 3rem;
`;
