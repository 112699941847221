import { IconButton } from '@redislabsdev/redis-ui-components';
import { ContractsIcon, NotificationsIcon } from '@redislabsdev/redis-ui-icons';
import { Loader, Tooltip } from '@redislabsdev/redislabs-ui-components';
import { TablePagination } from '@redislabsdev/redislabs-ui-components/ui/components/Table';
import { parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import ProtectedComponent from '../../components/ProtectedComponent/ProtectedComponent';
import { showToast } from '../../components/Toast/Toast';
import { grafanaClusterUrl } from '../../config';
import {
  MAINTENANCE_ADVANCE_NOTIFICATION_READ,
  MAINTENANCE_ADVANCE_NOTIFICATION_WRITE,
  MAINTENANCE_WINDOW_WRITE,
} from '../../constants/permissionsConstants';
import { StoreInterface } from '../../interfaces/storeInterfaces';
import { AdvanceNotificationConfirmModal } from './AdvanceNotificationConfirmModal';
import { MaintenanceWindowClustersFilters, Sort } from './MaintenanceWindow.types';
import MaintenanceWindowClusterEditModal from './MaintenanceWindowClusterEditModal';
import {
  Cluster,
  updateAccountAdvanceNotification,
  updateClusterNotes,
} from './MaintenanceWindowPage.api';
import { NoBreakCell, RightAlignCell, StyledTextWithTooltip } from './Table.style';
import { daysLookup } from './daysLookup';
import formatInTimeZone from './formatInTimeZone';
import MaintenanceWindowsTooltip from './MaintenanceWindowsTooltip';
import { formatScheduledMaintenanceWindow } from './format-scheduled-maintenance-window';
import Table, { TableProps } from './Table';
import { useClusters } from './hooks/useClusters';

const buildLastMaintenanceTooltip = (record: Cluster) => {
  return (
    <ul>
      <li>
        Include system log: {record.lastMaintenanceActivity?.includeSystemLog ? 'true' : 'false'}
      </li>
      <li>Include email: {record.lastMaintenanceActivity?.includeEmail ? 'true' : 'false'}</li>
      <li>
        Update maintenance status:{' '}
        {record.lastMaintenanceActivity?.setMaintenanceMode ? 'true' : 'false'}
      </li>
      <li>Sendgrid Id: {record.lastMaintenanceActivity?.sendgridId}</li>
      <li>Notes: {record.lastMaintenanceActivity?.internalNotes}</li>
    </ul>
  );
};

const createColumns = (
  onEditClick: (row: Cluster) => void,
  history: ReturnType<typeof useHistory>,
  onToggleNotificationClick: (row: Cluster) => void,
  userPermissions?: string[]
): TableProps<Cluster>['columns'] => [
  {
    header: 'RCP ID',
    render: 'rcpId',
    cellComponent: NoBreakCell,
    sortKey: 'rcpId',
  },
  {
    header: 'Mesh ID',
    render: 'meshId',
    cellComponent: NoBreakCell,
    sortKey: 'meshId',
  },
  {
    header: 'Subscription ID',
    render: 'subscriptionId',
    cellComponent: NoBreakCell,
    sortKey: 'subscriptionId',
  },
  {
    header: 'Account ID',
    render: 'accountId',
    cellComponent: NoBreakCell,
    sortKey: 'accountId',
  },
  {
    header: 'Account Name',
    render: 'accountName',
    cellComponent: NoBreakCell,
    sortKey: 'accountName',
  },
  {
    header: 'Cluster Version',
    render: 'swVersion',
    cellComponent: NoBreakCell,
    sortKey: 'swVersion',
  },
  {
    header: 'Node Count',
    render: 'nodeCount',
    cellComponent: NoBreakCell,
  },
  {
    header: 'Region',
    render: 'regionName',
    cellComponent: NoBreakCell,
    sortKey: 'regionName',
  },
  {
    header: 'Provider',
    render: 'cloudProviderName',
    cellComponent: NoBreakCell,
    sortKey: 'cloudProviderName',
  },
  {
    header: 'Advance Notification Eligible',
    protectedBy: MAINTENANCE_ADVANCE_NOTIFICATION_READ,
    render: (row: Cluster) => {
      const isMutable = userPermissions?.includes(MAINTENANCE_WINDOW_WRITE);
      if (isMutable) {
        return (
          <input
            type="checkbox"
            checked={row.accountAdvanceNotification}
            onChange={() => {
              return;
            }}
            onClick={() => {
              onToggleNotificationClick(row);
            }}
          />
        );
      }
      return <>{row.accountAdvanceNotification ? 'true' : ''}</>;
    },
  },
  {
    header: 'Require Advance Notification',
    protectedBy: MAINTENANCE_ADVANCE_NOTIFICATION_READ,
    render: (row: Cluster) => {
      if (!row.advanceNotification) {
        return null;
      }

      return 'true';
    },
  },
  {
    header: 'Maintenance Windows',
    render: ({ maintenanceWindows: windows }: Cluster) => {
      if (!windows.length) {
        return null;
      }
      const additionalWindows = windows.length > 1 ? `+${windows.length - 1}` : '';
      const cell = `${daysLookup[windows[0].days[0]]}… ${additionalWindows}`;
      return (
        <Tooltip
          tooltipContent={<MaintenanceWindowsTooltip windows={windows} />}
          textColor="#01112a"
          delayHide={100}
        >
          {cell}
        </Tooltip>
      );
    },
  },
  {
    header: 'Excluded',
    render: (row: Cluster) => <>{row.excludedOperations.join(', ')}</>,
  },
  {
    header: 'Opt In Version',
    render: 'optInVersion',
    cellComponent: NoBreakCell,
    sortKey: 'optInVersion',
  },
  {
    header: 'Opt In Request Time',
    sortKey: 'optInRequestTime',
    cellComponent: NoBreakCell,
    render: ({ optInRequestTime }: Cluster) => {
      if (!optInRequestTime) {
        return null;
      }

      const parsed = parseISO(optInRequestTime);
      return formatInTimeZone(parsed, 'd-MMM-y HH:mm:ss', 'UTC');
    },
  },
  {
    header: 'Under Maintenance',
    render: (row: Cluster) => {
      if (!row.maintenanceMode) {
        return null;
      }

      return 'true';
    },
  },
  {
    header: 'Scheduled Maintenance Window',
    cellComponent: NoBreakCell,
    protectedBy: MAINTENANCE_ADVANCE_NOTIFICATION_READ,
    render: ({
      scheduledMaintenanceWindowStart,
      scheduledMaintenanceWindowEnd,
      availableScheduledMaintenanceHours,
    }: Cluster) => {
      if (
        !scheduledMaintenanceWindowStart ||
        !scheduledMaintenanceWindowEnd ||
        availableScheduledMaintenanceHours === null
      ) {
        return null;
      }

      return (
        <>
          {formatScheduledMaintenanceWindow(
            scheduledMaintenanceWindowStart,
            scheduledMaintenanceWindowEnd,
            availableScheduledMaintenanceHours
          )}
        </>
      );
    },
  },
  ...(grafanaClusterUrl
    ? [
        {
          header: 'Grafana',
          render: (row: Cluster) => {
            if (!row.clusterUrl) {
              return null;
            }

            const link = grafanaClusterUrl?.replace(
              '%cluster',
              row.clusterUrl.replace(/^https?:\/\//, '')
            );
            return (
              <a href={link} target="_blank">
                Grafana
              </a>
            );
          },
          cellComponent: NoBreakCell,
        },
      ]
    : []),
  {
    header: 'Special Notes',
    render: 'clusterNotes',
  },
  {
    header: 'Off SM',
    render: ({ offSm }: Cluster) => <>{offSm ? 'true' : ''}</>,
  },
  {
    header: 'Last Maintenance Time',
    sortKey: 'lastMaintenanceTime',
    render: (row: Cluster) => {
      if (!row.lastMaintenanceActivity) {
        return null;
      }

      const parsed = parseISO(row.lastMaintenanceActivity.timestamp);
      return (
        <Tooltip tooltipContent={buildLastMaintenanceTooltip(row)} textColor={'black'}>
          <StyledTextWithTooltip>
            {formatInTimeZone(parsed, 'd-MMM-y HH:mm:ss', 'UTC')}
          </StyledTextWithTooltip>
        </Tooltip>
      );
    },
    cellComponent: NoBreakCell,
  },
  {
    header: 'Last Maintenance Activity',
    render: (row: Cluster) => {
      if (!row.lastMaintenanceActivity) {
        return null;
      }

      return (
        <Tooltip tooltipContent={buildLastMaintenanceTooltip(row)} textColor={'black'}>
          <StyledTextWithTooltip>
            {row.lastMaintenanceActivity.operations.join(', ')}
          </StyledTextWithTooltip>
        </Tooltip>
      );
    },
  },
  {
    header: '',
    cellComponent: RightAlignCell,
    render: (row: Cluster) => (
      <>
        <ProtectedComponent requiredPermissions={MAINTENANCE_WINDOW_WRITE}>
          <IconButton icon={ContractsIcon} size="XL" onClick={() => onEditClick(row)} />
        </ProtectedComponent>

        {row.maintenanceWindows.length && row.advanceNotification ? (
          <ProtectedComponent requiredPermissions={MAINTENANCE_ADVANCE_NOTIFICATION_WRITE}>
            <IconButton
              style={{ marginLeft: '1rem' }}
              icon={NotificationsIcon}
              size="XL"
              onClick={() => {
                history.push(
                  `/maintenanceWindow/advanceNotifications/create/${
                    row.meshId ? 'mesh' : 'cluster'
                  }/${row.subscriptionId}`
                );
              }}
            />
          </ProtectedComponent>
        ) : null}
      </>
    ),
  },
];

const initialPageSize = 10;

export type MaintenanceWindowTableProps = {
  filters: MaintenanceWindowClustersFilters;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  sort: Sort | null;
  setSort: React.Dispatch<React.SetStateAction<null | Sort>>;
};

const MaintenanceWindowTable: React.FC<MaintenanceWindowTableProps> = ({
  filters,
  page,
  setPage,
  sort,
  setSort,
}) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const userPermissions = useSelector((state: StoreInterface) => state.rootPage.permissions);
  const [size, setSize] = useState(initialPageSize);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);

  // setting to number value opens edit modal
  const [clusterEditClusterId, setClusterEditClusterId] = useState<number | null>(null);
  const { data: clusterData, isFetching: isLoadingClusters, refetch } = useClusters(
    page,
    size,
    filters,
    sort
  );

  useEffect(() => {
    if (!isLoadingClusters) {
      setTotalRecords(clusterData?.totalRecords || 0);
    }
  }, [clusterData, isLoadingClusters]);

  const editingCluster = clusterData?.records.find((c) => c.clusterId === clusterEditClusterId);

  const [
    currentAdvanceNotificationCluster,
    setCurrentAdvanceNotificationCluster,
  ] = useState<Cluster | null>(null);

  useEffect(() => {
    refetch();
  }, [filters]);

  const columns = createColumns(
    (cluster) => {
      setClusterEditClusterId(cluster.clusterId);
    },
    history,
    (cluster) => {
      setCurrentAdvanceNotificationCluster(cluster);
    },
    userPermissions
  );

  const pageCount = Math.ceil(totalRecords / size);

  return (
    <div data-testid="mw-clusters">
      {loading || isLoadingClusters ? (
        <div data-testid="mw-fetching-indicator">
          <Loader />
        </div>
      ) : (
        <>
          <Table
            data={clusterData?.records || []}
            columns={columns}
            sortKey={sort?.sortBy}
            sortDirection={sort?.sortDirection}
            onSort={({ sortKey, sortDirection }) => {
              setPage(0);
              setSort({ sortBy: sortKey, sortDirection });
            }}
            rowKey="clusterId"
          />
          <TablePagination
            isPaginatedControlled={true}
            rows={clusterData?.records || []}
            canNextPage={page + 1 < pageCount}
            canPreviousPage={page > 0}
            pageIndex={page}
            pageCount={pageCount}
            pageSize={size}
            gotoPage={(pageIndex: number) => {
              setPage(pageIndex);
            }}
            nextPage={() => {
              setPage(page + 1);
            }}
            previousPage={() => {
              setPage(page - 1);
            }}
            setPageSize={(newSize: number) => {
              setSize(newSize);
            }}
          />
        </>
      )}
      <MaintenanceWindowClusterEditModal
        cluster={editingCluster}
        onClose={() => {
          setClusterEditClusterId(null);
        }}
        onSubmit={async (clusterNotes) => {
          // programming error if this is not available
          if (!clusterEditClusterId) {
            throw new Error('Invalid cluster ID');
          }

          try {
            await updateClusterNotes(clusterEditClusterId, clusterNotes);
            const newClusterData = {
              totalRecords: clusterData?.totalRecords,
              records: clusterData?.records.map((c) => {
                if (c.clusterId === clusterEditClusterId) {
                  return {
                    ...c,
                    clusterNotes,
                  };
                }
                return c;
              }),
            };
            queryClient.setQueryData(['clusters', page, size, filters, sort], newClusterData);
            setClusterEditClusterId(null);
          } catch (error) {
            showToast('An error occurred while updating special notes.');
          }
        }}
      />
      <AdvanceNotificationConfirmModal
        cluster={currentAdvanceNotificationCluster}
        onSubmit={async () => {
          // also a programming error if this is not available
          if (!currentAdvanceNotificationCluster) {
            throw new Error('Invalid cluster for advance notification toggle');
          }
          try {
            const newValue = !currentAdvanceNotificationCluster.accountAdvanceNotification;
            setLoading(true);
            setCurrentAdvanceNotificationCluster(null);
            await updateAccountAdvanceNotification(
              currentAdvanceNotificationCluster.accountId,
              newValue
            );

            // reset page number if we're filtering on any of the advance notification fields
            // since toggling the checkbox can affect # of rows returned
            if (filters.advanceNotificationEligible || filters.requireAdvanceNotification) {
              setPage(0);
            }

            refetch();
          } catch (error) {
            showToast('An error occurred while toggling advance notification.');
          } finally {
            setLoading(false);
          }
        }}
        onCancel={() => {
          setCurrentAdvanceNotificationCluster(null);
        }}
      />
    </div>
  );
};

export default MaintenanceWindowTable;
