import styled from 'styled-components/macro';
// @ts-ignore
import { ReactComponent as RedisLogo } from '../../assets/icons/RedisLogoMinimized.svg';

export const LoginPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  margin: 0 -4rem;
`;

export const RedisIcon = styled(RedisLogo)`
  margin-right: 1.5rem;
  margin-top: 0.5rem;
`;

export const LoginPageCenterContent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 5rem;

  & > div:first-child {
    display: flex;
    flex-direction: row;
    align-content: center;
  }
`;

export const LinkWrapper = styled.div`
  width: 100%;
  font-size: 1.7rem;
  text-decoration: none;
  text-align: right;
`;
