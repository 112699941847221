import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useField, useFormikContext } from 'formik';
import moment from 'moment';
import { Loader, SingleSelectorDropDown } from '@redislabsdev/redislabs-ui-components';
import { Button, DatePicker } from '@redislabsdev/redis-ui-components';
import { CheckBoldIcon } from '@redislabsdev/redis-ui-icons';
import {
  DurationWindow,
  getSubscriptionMetadata,
  SubscriptionMetadataResponse,
} from './ScheduledMaintenance.api';
import {
  StyledError,
  StyledFormColumn,
  StyledFormRow,
  StyledFormWrapper,
  StyledInnerRowStatic,
  StyledReadonlyValue,
} from './Form.style';
import { timeOptions } from './Select.options';
import { ErrorComponent, SuccessComponent, WarningComponent } from './MaintenanceWindowPage.style';
import { EmailTemplatesResponse, getEmailTemplatesRequest } from './MaintenanceWindowPage.api';
import { daysLookup } from './daysLookup';
import { formatTime } from './format-time';
import { Operations } from './Operations';

const defaultStartTime = '12:00 AM';
const blankOption = { id: '', title: '' };

export const offsetsToRanges = (offsetStart: number, offsetEnd: number) => {
  if (offsetStart <= offsetEnd) {
    return [[offsetStart, offsetEnd]];
  }

  return [
    [offsetStart, 168],
    [0, offsetEnd],
  ];
};

const hoursInWeek = 24 * 7;

export const getHoursLeftInMaintenanceWindow = (
  date: moment.Moment,
  windows: DurationWindow[] | undefined
) => {
  const dayOffset = daysLookup.indexOf(date.format('dddd'));
  const selectedOffset = dayOffset * 24 + parseInt(date.format('H'), 10);

  let hoursInWindow = 0;
  let matchingWindowId: number | undefined;

  for (const k in windows) {
    const win: DurationWindow = windows[k];
    const day = win.day;
    const start = day * 24 + win.from;
    const end = mod(start + win.duration, hoursInWeek);

    const ranges = offsetsToRanges(start, end);
    if (
      ranges.some(
        ([rangeStart, rangeEnd]) => selectedOffset >= rangeStart && selectedOffset <= rangeEnd - 1
      )
    ) {
      if (win.duration > hoursInWindow) {
        hoursInWindow = win.duration;
        matchingWindowId = win.subscriptionCustomWindowId;
      }
    }
  }

  return { hoursInWindow, matchingWindowId };
};

export type AdvancedNotificationsFormState = {
  subscriptionId: number;
  subscriptionType: string;
  maintenanceDate: string;
  operations: string[];
  emailTemplate: string;
  subscriptionCustomWindowId?: number;
};

export type AdvanceNotificationRouterParams = {
  subscriptionId: string;
  subscriptionType: string;
};

type DurationWindowsTooltipProps = {
  windows: DurationWindow[];
  matchingWindowId?: number;
};

const mod = (x: number, n: number) => ((x % n) + n) % n;

const DurationWindowsTooltip = ({ windows, matchingWindowId }: DurationWindowsTooltipProps) => {
  const lines = windows.map((win, i) => {
    const day = daysLookup[win.day];
    const hour = mod(win.from, 12) || 12; // if 0 then return 12 for 12 AM
    const isSelected = matchingWindowId === win.subscriptionCustomWindowId;
    const line = (
      <li
        key={i}
        style={{
          ...(matchingWindowId === win.subscriptionCustomWindowId
            ? { color: 'rgb(36,79,62)', textDecoration: 'underline' }
            : {}),
        }}
      >
        {`${day} from ${formatTime(hour)} ${win.from < 12 ? 'AM' : 'PM'} for ${
          win.duration
        }h (UTC)`}
        {isSelected ? <CheckBoldIcon size="M" /> : null}
      </li>
    );
    return line;
  });
  const tooltip = <ul>{lines}</ul>;
  return tooltip;
};

const ScheduledMaintenanceForm = () => {
  const { subscriptionId, subscriptionType } = useParams<AdvanceNotificationRouterParams>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState(true);
  const [subscriptionMetadata, setSubscriptionMetadata] = useState<
    SubscriptionMetadataResponse | undefined
  >();

  const [error, setError] = useState<string | undefined>();
  const [dateError, setDateError] = useState<string | undefined>();
  const [dateMessage, setDateMessage] = useState<string | undefined>();
  const [dateWarning, setDateWarning] = useState<string | undefined>();
  const [startTime, setStartTime] = useState<string>(defaultStartTime);
  const [maintenanceDate, setMaintenanceDate] = useState<Date | undefined>(undefined);
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplatesResponse[]>([]);
  const [, , subscriptionCustomWindowIdFieldHelpers] = useField({
    name: 'subscriptionCustomWindowId',
  });
  const [, , maintenanceDateFieldHelpers] = useField({ name: 'maintenanceDate' });
  const [, , emailTemplateHelpers] = useField({ name: 'emailTemplate' });

  const { values, errors, isSubmitting, handleSubmit } = useFormikContext<
    AdvancedNotificationsFormState
  >();

  useEffect(() => {
    getSubscriptionMetadata(subscriptionId, subscriptionType)
      .then((result) => {
        setSubscriptionMetadata(result.data);
      })
      .catch(() => {
        setError('Unable to fetch subscription details.');
      })
      .finally(() => {
        setIsLoading(false);
      });

    getEmailTemplatesRequest()
      .then((result) => {
        setEmailTemplates(result.data);
      })
      .catch(() => {
        setError('Unable to fetch email templates.');
      })
      .finally(() => {
        setIsLoadingTemplates(false);
      });
  }, []);

  const selectableTemplates = useMemo(
    () =>
      emailTemplates
        .filter((template) => template.maintenance_type === 'advanceNotification')
        .map((template) => ({
          id: template.friendly_name,
          title: template.friendly_name,
        })),
    [emailTemplates]
  );

  const handleDateTimeChange = (
    dateValue: Date | undefined = undefined,
    timeValue: string | undefined = undefined
  ) => {
    const localDateValue = dateValue || maintenanceDate;
    const localTimeValue = timeValue || startTime;

    maintenanceDateFieldHelpers.setValue('');

    if (!localDateValue || !localTimeValue) {
      return;
    }

    const fullDate = moment.utc(
      moment(localDateValue).utcOffset(0, true).format('YYYY-MM-DD') + ' ' + localTimeValue,
      'YYYY-MM-DD hh:mm A'
    );

    setDateMessage(undefined);
    setDateWarning(undefined);
    subscriptionCustomWindowIdFieldHelpers.setValue(undefined);
    setMaintenanceDate(localDateValue);
    if (moment.utc().isAfter(fullDate)) {
      setDateError('You must select a date in the future.');
      maintenanceDateFieldHelpers.setValue(undefined);
    } else {
      // validate maintenance window
      const { hoursInWindow, matchingWindowId } = getHoursLeftInMaintenanceWindow(
        fullDate,
        subscriptionMetadata?.windows
      );
      if (!hoursInWindow) {
        setDateError('Selected date/time does not fall within an available maintenance window.');
      } else {
        subscriptionCustomWindowIdFieldHelpers.setValue(matchingWindowId);
        maintenanceDateFieldHelpers.setValue(fullDate.format('YYYY-MM-DD HH:mm:ss'));
        maintenanceDateFieldHelpers.setTouched(true, false);
        setDateError(undefined);
        setDateMessage(
          `${fullDate.format(
            'DD-MMM-y HH:mm:ss'
          )} falls within a maintenance window with ${hoursInWindow} hour${
            hoursInWindow === 1 ? '' : 's'
          } available.`
        );

        // warning for dates <48 hours from now
        if (moment.utc().add(48, 'hours').isAfter(fullDate)) {
          setDateWarning('Selected date/time is less than 48 hours from current time.');
        }
      }
    }
  };

  return (
    <div>
      {(isLoading || isLoadingTemplates) && (
        <div data-testid="mw-fetching-indicator">
          <Loader />
        </div>
      )}
      {error && <ErrorComponent>{error}</ErrorComponent>}
      {!error && !isLoadingTemplates && !selectableTemplates.length && (
        <ErrorComponent>
          There are no available advanced notification email templates. Please first add at least
          one advanced notification email template first.
        </ErrorComponent>
      )}
      {!error && !isLoading && !isLoadingTemplates && !!selectableTemplates.length && (
        <form
          onSubmit={(e?: React.FormEvent<HTMLFormElement>) => {
            handleSubmit(e);
          }}
        >
          <StyledFormWrapper>
            <StyledFormRow>
              <StyledFormColumn>
                <StyledInnerRowStatic>
                  <label htmlFor="accountName">
                    Account Name
                    <StyledReadonlyValue>
                      {subscriptionMetadata?.metadata.accountName}
                    </StyledReadonlyValue>
                  </label>
                  <label htmlFor="accountId">
                    Account Id
                    <StyledReadonlyValue>
                      {subscriptionMetadata?.metadata.accountId}
                    </StyledReadonlyValue>
                  </label>
                </StyledInnerRowStatic>
                <StyledInnerRowStatic>
                  <label htmlFor="subscriptionName">
                    Subscription Name
                    <StyledReadonlyValue>
                      {subscriptionMetadata?.metadata.subscriptionName}
                    </StyledReadonlyValue>
                  </label>
                  <label htmlFor="subscriptionId">
                    Subscription Id
                    <StyledReadonlyValue>{subscriptionId}</StyledReadonlyValue>
                  </label>
                </StyledInnerRowStatic>
                <label htmlFor="configuredMaintenanceWindows">
                  Configured Maintenance Windows
                  <StyledReadonlyValue>
                    {subscriptionMetadata?.windows ? (
                      <DurationWindowsTooltip
                        windows={subscriptionMetadata?.windows}
                        matchingWindowId={values.subscriptionCustomWindowId}
                      />
                    ) : null}
                  </StyledReadonlyValue>
                </label>

                <Operations />

                <label htmlFor="emailTemplate">
                  Email Template
                  <SingleSelectorDropDown
                    data-role="dropdown-button"
                    optionList={selectableTemplates}
                    borderRadius
                    defaultValue={blankOption}
                    borderColor="gray1"
                    headerBorder
                    getSelectedOption={(item) => {
                      emailTemplateHelpers.setValue(`${item?.id}`);
                      emailTemplateHelpers.setTouched(true, false);
                    }}
                  />
                  {errors.emailTemplate && <StyledError>{errors.emailTemplate}</StyledError>}
                </label>
              </StyledFormColumn>

              <StyledFormColumn style={{ gap: '0' }}>
                <label htmlFor="maintenanceDate">
                  Select Maintenance Date
                  {dateMessage && <SuccessComponent>{dateMessage}</SuccessComponent>}
                  {dateError && <ErrorComponent>{dateError}</ErrorComponent>}
                  {dateWarning && <WarningComponent>{dateWarning}</WarningComponent>}
                  <DatePicker
                    onSingleSelect={(value) => {
                      handleDateTimeChange(value);
                    }}
                    selectedDay={maintenanceDate}
                  />
                </label>
                <label htmlFor="fromTime" style={{ width: '50%' }}>
                  Time
                  <SingleSelectorDropDown
                    data-role="dropdown-button"
                    optionList={timeOptions}
                    defaultValue={{
                      id: defaultStartTime,
                      title: defaultStartTime,
                    }}
                    reset={startTime === defaultStartTime}
                    borderRadius
                    borderColor="gray1"
                    headerBorder
                    getSelectedOption={(item) => {
                      if (`${item?.id}` !== startTime) {
                        setStartTime(`${item?.id}`);
                        handleDateTimeChange(undefined, `${item?.id}`);
                      }
                    }}
                  />
                </label>
                {errors.maintenanceDate && <StyledError>{errors.maintenanceDate}</StyledError>}
              </StyledFormColumn>
            </StyledFormRow>
            <Button
              variant="primary"
              type="submit"
              disabled={isLoading || isLoadingTemplates || isSubmitting}
            >
              Submit Advance Notification
            </Button>{' '}
            <Button
              variant="secondary-ghost"
              onClick={() => {
                history.push('/maintenanceWindow/advanceNotifications');
              }}
            >
              Cancel
            </Button>
          </StyledFormWrapper>
        </form>
      )}
    </div>
  );
};

export default ScheduledMaintenanceForm;
