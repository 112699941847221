import { ChevronRightIcon, CaretDownIcon } from '@redislabsdev/redis-ui-icons';
import { useState } from 'react';

export const ExpandableContent = ({
  headerText,
  children,
}: React.PropsWithChildren<{ headerText: string }>) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleHeaderClick = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div style={{ marginBottom: '1rem ' }}>
      <p onClick={handleHeaderClick} style={{ cursor: 'pointer', fontWeight: 'bold' }}>
        {isExpanded ? CaretDownIcon({ size: 'S' }) : ChevronRightIcon({ size: 'S' })} {headerText}
      </p>
      {isExpanded && children}
    </div>
  );
};
